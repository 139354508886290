import { MsalService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MsalLoginGuard implements CanActivate {

  constructor(
    private msal: MsalService,
    private _authService: AuthenticationService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.msal.instance.getActiveAccount() == null) {
        console.log('not logged in!')
        return false;
    }
    else {
        this._authService.setIsAuthorized(true);
        return true;
    };
  }

}