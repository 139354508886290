import { first } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { regexValidators } from 'providers/validator/validator';
import { StatsVm } from 'app/pages/dashboard/dashboard.component';
import { TeamSelectionComponent } from 'app/pages/teams-area/team-selection/team-selection.component';
import { CreateEventComponent } from 'app/pages/calendar-area/create-event/create-event.component';
import * as FileSaver from "file-saver";
import { UploadFileComponent } from 'app/pages/multimedia-area/upload-file/upload-file.component';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { OnboardService } from 'app/onboard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMPANY } from 'app/pages/admin-area/admin-companies/admin-companies.component';


@Component({
  selector: 'app-view-issues',
  templateUrl: './view-issues.component.html',
  styleUrls: ['./view-issues.component.css']
})
export class ViewIssuesComponent implements OnInit {
  private subscriptionUser: Subscription;

  constructor(private _authService: AuthenticationService,
    private readonly _configService: ConfigService,
    private modalService: NgbModal,
    private router: Router,
    private sanitizer: DomSanitizer,
    private tourService: OnboardService) { }

    user: string = '';
    token: string = '';
    issueId: string = '';
    role;
    company;
    resolved: Boolean = false;
    escalated: Boolean = false;
    creator;
    created;
    team;
    creatorId;
    teamId;
  
    isUserManager: boolean = false;
    isThereACompany: Boolean = false;
    isProfileComplete: boolean = false;
    isChallengeExpired: boolean = false;
    respondParticipant: boolean = false;
    respondCoach: boolean = false;
    public issueData = [];
    isMobile;

    participantBody: string = '';
    coachBody: string = '';
  

  async ngOnInit(): Promise<void> {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.user = user['_id'];
      this.token = user['token'];
      this.role = user['role'];
      this.company = user["company"]._id;

      if (user['company']) this.isThereACompany = true;
      else this.isThereACompany = false;

      if (user['role'] == 'Admin' || user['role'] == 'CompanyAdmin' || user['role'] == 'Manager') this.isUserManager = true;
      else this.isUserManager = false;

      // if (this.stats && this.stats.profileCompletition == 100) this.isProfileComplete = true;
      if (user['isProfileComplete']) this.isProfileComplete = true;
      else this.isProfileComplete = false;
    });

    this.issueId = await this._authService.getIssue();
    if (!this.issueId) this.router.navigate(['/participant-issues']);

    await this.getIssue();

  }

  
  messageForCoach: string = null;
  messageForParticipant: string = null;

  async getIssue() {

    const issueVm = {
      id: this.user,
      token: this.token,
      issueId: this.issueId,
    };

    await (await this._authService.getIssueProfile(issueVm)).subscribe(data => {
      this.issueData = data;
      this.resolved = this.issueData['solved'];
      this.escalated = this.issueData['escalate']
      this.messageForCoach = this.issueData['coachMessage'];
      this.messageForParticipant = this.issueData['participantMessage'];
      this.creatorId = this.issueData['creator'];
      this.created = this.issueData['createdAt'];
      this.teamId = this.issueData['team'];
      console.log('this.issueData :>> ', this.issueData);
      this.getTeam();
      this.getCreator();
      return this.issueData;
    }, err => {
      console.log('error');
    });
  }

  async resolveIssue(){
    const issueVm = {
      id: this.user,
      token: this.token,
      issueId: this.issueId,
      solved: true
    };

    await (await this._authService.closeParIssue(issueVm)).subscribe(data => {
      this.getIssue();
    })
    


  }

  async escalateIssue(){
    const issueVm = {
      id: this.user,
      token: this.token,
      issueId: this.issueId,
      escalate: true
    };

    await (await this._authService.escalateIssue(issueVm)).subscribe(data => {
      this.getIssue();
    })
  }

  async submitCoach(){
    const issueVm = {
      id: this.user,
      token: this.token,
      issueId: this.issueId,
      message: this.coachBody
    };

    await (await this._authService.messageCoach(issueVm)).subscribe(data => {
      this.getIssue();
    })
  }


  async submitParticipant(){

    const issueVm = {
      id: this.user,
      token: this.token,
      issueId: this.issueId,
      message: this.participantBody
    };

    await (await this._authService.messageParticipant(issueVm)).subscribe(data => {
      this.getIssue();
    })
  }

  async getTeam(){
    let teamVm = {
      id: this.user,
      token: this.token,
      team: this.teamId
    }

    await (await this._authService.getTeamProfile(teamVm)).subscribe(data => {
      console.log(data)
      this.team = data.team.name;
    })
  }

  async getCreator(){
    let userVm = {
      id: this.user,
      token: this.token,
      user: this.creatorId
    }

      await (await this._authService.getUserProfileNoPub(userVm)).subscribe(data => {
        console.log(data)
        this.creator = data.user;
      })
  }


}
