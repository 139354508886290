import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { StoreProvider } from 'providers/store/store';
import { Subscription } from 'rxjs';
import { regexValidators } from 'providers/validator/validator';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { EscalateModalComponent } from './escalate-modal/escalate-modal.component';

declare var $: any;

export class TEAM {
    id: string = '';
    name: string = '';
    description: string = '';
    active: boolean = false;
}


export interface SKILL {
    value: string;
    display: string;

}

@Component({
  selector: 'app-modals',
  templateUrl: 'modals.component.html',
  styleUrls: ['modals.component.css'],
})
export class ModalsComponent implements OnInit {

  showResponseSection: boolean = false;
  responseText: string = '';
  isBold = false;
  isItalic = false;
  private subscriptionUser: Subscription;
  isDeleteModalVisible: boolean;
  profileform: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private storage: StoreProvider,
        private router: Router,
        private modalService: NgbModal,
        private readonly _configService: ConfigService,
        public activeModal: NgbActiveModal,
  ) {}

  user = {
    id: '',
    publicid: '',
    token: '',
    name: '',
    surname: '',
    fullName: '',
    email: '',
    avatar: '',
    company: '',
    industry: '',
    companyRole: '',
    knowledge: '',
    bio: [''],
    skills: '',
    strengthsAndCompetencies: '',
    hobbies: '',
    interests: '',
    emailVerification: null,
    learnNewSkills: '',
    yearsCurrentRole: '',
    yearsSimilarRole: '',
    yearsPreviousRole: '',
    age:'',
    gender:'',
    ethnicity:'',
    leaderExp:'',
};

team: TEAM = new TEAM();
    isThereTeam: boolean = false;
    isTherePicture: boolean = false;

  ngOnInit(): void {

    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}

      this.user.id = user['_id'];
      this.user.publicid = user['publicid'];
      this.user.token = user['token'];
      this.user.fullName = user['name'] + ' ' + user['surname'];
      this.user.name = user['name'];
      this.user.surname = user['surname'];
      this.user.email = user['email'];
      // this.user.company = user['company'].name;
      this.user.industry = user['industry'];
      this.user.companyRole = user['companyRole'];
      this.user.avatar = this._configService.url + user['avatar'];
      this.user.knowledge = user['knowledge'];
      //   this.user.skills = user['skills'];
      this.user.strengthsAndCompetencies = user['strengthsAndCompetencies'];
      this.user.hobbies = user['hobbies'];
      this.user.bio = user['bio'].split("<br>");
      this.user.interests = user['interests'];
      this.user.learnNewSkills = user['learnNewSkills'];
      this.user.yearsCurrentRole = user['yearsCurrentRole'];
      this.user.yearsSimilarRole = user['yearsSimilarRole'];
      this.user.yearsPreviousRole = user['yearsPreviousRole'];
      this.user.age = user['age'];
      this.user.gender = user['gender'];
      this.user.ethnicity = user['ethnicity'];
      this.user.leaderExp = user['leaderExp'];

      this.user.skills = user['skills'].map(a => {

          return {

              display: a.title,
              value: a._id

          }

      });

      this.isTherePicture = user['avatar'] != 'resources/assets/avatar/avatar.png' ? true : false;

      if (user['company']) this.user.company = user['company'].name;

      const team = user['team'];

      if (team) {
          this.isThereTeam = true;
          this.team = {
              id: team._id,
              name: team.name,
              description: team.description,
              active: team.active
          }
      }

      this.profileform = this.formBuilder.group({
          name: [this.user.name, Validators.required],
          surname: [this.user.surname, Validators.required],
          company: [this.user.company, Validators.required],
          industry: [this.user.industry],
          companyRole: [this.user.companyRole, Validators.required],
          knowledge: [this.user.knowledge],
          skills: [this.user.skills],
          strengthsAndCompetencies: [this.user.strengthsAndCompetencies],
          hobbies: [this.user.hobbies],
          interests: [this.user.interests],
          email: [this.user.email, [Validators.compose([Validators.pattern(regexValidators.email), Validators.required, Validators.minLength(6), Validators.email])]],
          learnNewSkills: [this.user.learnNewSkills],
          yearsCurrentRole: [this.user.yearsCurrentRole],
          yearsSimilarRole: [this.user.yearsSimilarRole],
          yearsPreviousRole: [this.user.yearsPreviousRole],
          age: [this.user.age],
          gender: [this.user.gender],
          ethnicity: [this.user.ethnicity],
          leaderExp: [this.user.leaderExp],
      });
  });

  }

  onRespondClick() {
    this.showResponseSection = true;
  }

  onEscalateClick() {
    // Handle escalate action here
    this.activeModal.close();
    this.modalService.open(EscalateModalComponent, { size: 'md', backdrop: 'static', centered: true })

  }

  onDeleteClick() {
    // Open the confirmation modal for delete
    this.activeModal.close();
    this.modalService.open(DeleteModalComponent, { size: 'md', backdrop: 'static', centered: true })
  }

  onPostResponseClick() {
    // Handle post response action here
    // You can use this.responseText to send the response.
    this.showResponseSection = false;
  }

  onCloseClick() {
    this.activeModal.close();
  }

  toggleBoldText() {
    // Check if bold is already applied
    if (this.isBold) {
      // Remove bold by replacing the selected text
      this.responseText = this.responseText.replace('<strong>', '').replace('</strong>', '');
    } else {
      // Apply bold to the selected text
      const selectedText = window.getSelection().toString();
      this.responseText = this.responseText.replace(
        selectedText,
        '<strong>' + selectedText + '</strong>'
      );
    }
    this.isBold = !this.isBold;
  }

  toggleItalicText() {
    // Check if bold is already applied
    if (this.isItalic) {
      // Remove bold by replacing the selected text
      this.responseText = this.responseText.replace('<i>', '').replace('</i>', '');
    } else {
      // Apply bold to the selected text
      const selectedText = window.getSelection().toString();
      this.responseText = this.responseText.replace(
        selectedText,
        '<i>' + selectedText + '</i>'
      );
    }
    this.isItalic = !this.isItalic;
  }
}
