import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'providers/authentication.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigService } from 'providers/config/config.service';

declare var $: any;

export interface IDEA {
  id: string;
  title: string;
  text: string[];
  index?: string;
  // shortTitle: string;
  creator: string;
  creator_id: string;
  // originalTitle: string;
  flagged: boolean;
  skills: [];
  createdAt: Date;
  timeFromNow: string;
}

export interface SKILL {
  value: string;
  display: string;
  
}

export class TEAM {
  id: string = '';
  name: string = '';
  description: string = '';
  active: boolean = false;
}

export interface SKILL {
  value: string;
  display: string;
}

@Component({
  selector: 'app-idea-view',
  templateUrl: './idea-view.component.html',
  styleUrls: ['./idea-view.component.css']
})
export class IdeaViewComponent implements OnInit, OnDestroy {
  skip = 0;
  noRatings = true;
  ideaTitle = '';
  ideaText = '';
  skills = [];
  allowRatings = false;
  
  loadingIndicator = false;
  
  allSkills: any;
  public skillRecords: SKILL[] = [];

  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  private subscriptionUser: Subscription;

  allIdeasRecords: any;
  ideasRecords: IDEA[] = [];

  allRatedIdeasRecords: any;
  public ratingRecords: IDEA[] = [];
  
  @Input() isAComponent: boolean = false;
  
  submitted = false;
  showError = false;
  success = false;
  loading = false;
  error = '';
  
  user: string = '';
  token: string = '';
  company: string = '';
  id_challenge: string = '';
  
  id_idea: string = '';

  challengeTitle;
  currentImageUrl: string;

  endDate;
  
  constructor(
    private _authService: AuthenticationService,
    private router: Router,
    private _location: Location,
    private readonly _configService: ConfigService,
    ) { }

    historyBack() {
      this._location.back();
    }
    
    isMobile: boolean = false;
    isSpecial: boolean = false;

  showResponseSection: boolean = false;
  responseText: string = '';
  isBold = false;
  isItalic = false;
  isSpecialRecognitionVisible: boolean;

  user1 = {
    id: '',
    publicid: '',
    token: '',
    name: '',
    surname: '',
    fullName: '',
    email: '',
    avatar: '',
    company: '',
    industry: '',
    companyRole: '',
    knowledge: '',
    bio: [''],
    skills: '',
    strengthsAndCompetencies: '',
    hobbies: '',
    interests: '',
    emailVerification: null,
    learnNewSkills: '',
    yearsCurrentRole: '',
    yearsSimilarRole: '',
    yearsPreviousRole: '',
    age:'',
    gender:'',
    ethnicity:'',
    leaderExp:'',
};

team: TEAM = new TEAM();
    isThereTeam: boolean = false;
    isTherePicture: boolean = false;

    async ngOnInit() {

      this.endDate = localStorage.getItem('challengeDeadline');

      this.challengeTitle = localStorage.getItem('challengeTitle');

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

      // Check if the page contains a iFrame element
    const iframeElement = document.querySelector('iframe');
    console.log("isSpecial: ",this.isSpecial, " before if statement")
    console.log(iframeElement, " Iframe Element");

    // If a iFrame element is found, set isSpecial to true
    if (iframeElement) {
      this.isSpecial = true;
      console.log(iframeElement);
      console.log("isSpecial: ", this.isSpecial, " After If Statement")
    } else {
      this.isSpecial = false;
    }
      
    this.subscriptionUser = await this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.user = user['_id'];
      this.token = user['token'];
      this.company = user['company'];
      this.user1.id = user['_id'];
      this.user1.publicid = user['publicid'];
      this.user1.token = user['token'];
      this.user1.fullName = user['name'] + ' ' + user['surname'];
      this.user1.name = user['name'];
      this.user1.surname = user['surname'];
      this.user1.email = user['email'];
      // this.user.company = user['company'].name;
      this.user1.industry = user['industry'];
      this.user1.companyRole = user['companyRole'];
      this.user1.avatar = this._configService.url + user['avatar'];
      this.user1.knowledge = user['knowledge'];
      //   this.user.skills = user['skills'];
      this.user1.strengthsAndCompetencies = user['strengthsAndCompetencies'];
      this.user1.hobbies = user['hobbies'];
      this.user1.bio = user['bio'].split("<br>");
      this.user1.interests = user['interests'];
      this.user1.learnNewSkills = user['learnNewSkills'];
      this.user1.yearsCurrentRole = user['yearsCurrentRole'];
      this.user1.yearsSimilarRole = user['yearsSimilarRole'];
      this.user1.yearsPreviousRole = user['yearsPreviousRole'];
      this.user1.age = user['age'];
      this.user1.gender = user['gender'];
      this.user1.ethnicity = user['ethnicity'];
      this.user1.leaderExp = user['leaderExp'];

      this.user1.skills = user['skills'].map(a => {
          return {
              display: a.title,
              value: a._id
          }

      });

      this.isTherePicture = user['avatar'] != 'resources/assets/avatar/avatar.png' ? true : false;

      if (user['company']) this.user1.company = user['company'].name;

      const team = user['team'];

      if (team) {
          this.isThereTeam = true;
          this.team = {
              id: team._id,
              name: team.name,
              description: team.description,
              active: team.active
          }
      }
    });
    
    if (!this.company) this.router.navigate(['/dashboard']);
    
    this.id_challenge = await this._authService.getChallenge();
    if (!this.id_challenge) this.router.navigate(['/challenge-view-list']);
    // this.idea.skills = idea['skills'];

    this.id_idea = await this._authService.getIdea();

    const storedImageUrl = localStorage.getItem('selectedImageUrl');
    if (storedImageUrl) {
      // If an image URL is stored, use it
      this.currentImageUrl = storedImageUrl;
    } else {
      // If no image URL is stored, set a default image URL
      this.currentImageUrl ="../../assets/img/challenges/tim-gouw--gjHizUfFlM-unsplash.jpg";

      // Store the default image URL in localStorage
      localStorage.setItem('selectedImageUrl', this.currentImageUrl);
    }
    
    await this.getAllIdeas();
    await this.getSkills()
    // await this.getAllRatedIdeas();
  }

  // Function to toggle the visibility of the special recognition card
  showSpecialRecognitionCard() {
    this.isSpecialRecognitionVisible = true;
  }

  // Function to hide the special recognition card
  hideSpecialRecognitionCard() {
    this.isSpecialRecognitionVisible = false;
  }

  submitSpecialRecognition() {
    this.isSpecialRecognitionVisible = false;
  }

  onRespondClick() {
    this.showResponseSection = true;
  }

  onEscalateClick() {
    // Handle escalate action here
  }

  onDeleteClick() {
    // Open the confirmation modal for delete
    if (confirm('Are you sure you want to delete the issue?')) {
      // Handle delete action here
    }
  }

  onPostResponseClick() {
    // Handle post response action here
    // You can use this.responseText to send the response.
    this.showResponseSection = false;
  }

  toggleBoldText() {
    // Check if bold is already applied
    if (this.isBold) {
      // Remove bold by replacing the selected text
      this.responseText = this.responseText.replace('<strong>', '').replace('</strong>', '');
    } else {
      // Apply bold to the selected text
      const selectedText = window.getSelection().toString();
      this.responseText = this.responseText.replace(
        selectedText,
        '<strong>' + selectedText + '</strong>'
      );
    }
    this.isBold = !this.isBold;
  }

  toggleItalicText() {
    // Check if bold is already applied
    if (this.isItalic) {
      // Remove bold by replacing the selected text
      this.responseText = this.responseText.replace('<i>', '').replace('</i>', '');
    } else {
      // Apply bold to the selected text
      const selectedText = window.getSelection().toString();
      this.responseText = this.responseText.replace(
        selectedText,
        '<i>' + selectedText + '</i>'
      );
    }
    this.isItalic = !this.isItalic;
  }

  openChangeImageModal() {
    // Trigger the modal using jQuery or Angular Material, or any other method you prefer
    // Here, we assume you have jQuery included
    $('#change-image-modal').modal('show');
  }

  saveImageChanges(selectedImageSrc: string) {
    // Update the current image URL with the selected image URL
    this.currentImageUrl = selectedImageSrc;

     // Store the selected image URL in localStorage
     localStorage.setItem('selectedImageUrl', selectedImageSrc);

    // Close the modal (assuming you are using Bootstrap modal)
    $('#change-image-modal').modal('hide');
  }

  ngOnDestroy() {
    this.subscriptionUser.unsubscribe();
  }
  
  async getAllIdeas() {
    
    const ideaVm = {
      id: this.user,
      token: this.token,
      challenge: this.id_challenge,
      skip: this.skip,
    };
    
    await (await this._authService.getAllIdeas(ideaVm)).subscribe(newData => {
      this.allIdeasRecords = [];
      this.ideasRecords = [];
      this.allIdeasRecords = newData;
      for (let index = 0; index < this.allIdeasRecords.length; index++) {
        const idea = this.allIdeasRecords[index];
        this.ideasRecords.push({
          id: idea._id,
          // shortTitle: idea.title.split("<br>")[0].length > 10 ? idea.title.split("<br>")[0].substring(0, 10) : idea.title.split("<br>")[0],
          // shortTitle: idea.title.replace(/<br>/g, '\n').length > 100 ? idea.title.replace(/<br>/g, '\n').substring(0, 100) : idea.title.replace(/<br>/g, '\n'),
          title: idea.title,
          // text: idea.text.split("<br>"),
          text: idea.text.split("<br>"),
          createdAt: idea.createdAt,
          flagged: idea.flagged,
          skills: idea.skills,
          creator: idea.creator.name + ' ' + idea.creator.surname,
          creator_id: idea.creator._id,
          timeFromNow: this.transform(idea.createdAt),
        })
      }
      this.ideasRecords.sort(function (a, b) {
        if (a.createdAt > b.createdAt) { return -1; }
        if (a.createdAt < b.createdAt) { return 1; }
        return 0;
      });
      console.log(this.ideasRecords);
      for(let i = 0; i<=this.ideasRecords.length - 1; i++){
        if(this.ideasRecords[i].id == this.id_idea){
          this.specificRecord.push(this.ideasRecords[i]);
        }
      }
      this.setRecords(this.specificRecord);
    }, err => {
      console.log('error');
    });
  }
  
  specificRecord = [];
  recordDetails = {
    title: '',
    text: '',
    creator: '',
    skills: []
  }

  setRecords(records = []){
    this.recordDetails.title = records[0].title;
    this.recordDetails.text = records[0].text;
    this.recordDetails.creator = records[0].creator;
    this.recordDetails.skills = records[0].skills;
    console.log(this.recordDetails)
  }
  
  async getSkills() {
    
    const skillsVm = {
      id: this.user,
      token: this.token,
    };

    this.loadingIndicator = true;

    await (await this._authService.getSkills(skillsVm)).subscribe(newData => {
        let category = [];
        this.allSkills = [];
        this.skillRecords = [];
        this.allSkills = newData;
        for (let index = 0; index < this.allSkills.length; index++) {

            const skill = this.allSkills[index];
            category.push(skill.category)
            this.skillRecords.push({
                value: skill._id,
                display: skill.title,
            })
        }
        this.loadingIndicator = false;
    }, err => {
        console.log('error');
    });



}

transform(value: string) {
  let now = moment(value).fromNow();
  return now;
}



}
