import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'providers/config/config.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-townhallinvite',
  templateUrl: './townhallinvite.component.html',
  styleUrls: ['./townhallinvite.component.css']
})
export class TownhallinviteComponent implements OnInit {

  constructor(private config: ConfigService, private http: HttpClient) { }

  ngOnInit(): void {
  }

  url = this.config.url;

       

  townHall(){
    this.http.post<any>(this.url + 'verificationGeneration/sendTownhallMulti', {  }).subscribe(data => {
        console.log("success")
      }, err => {
        console.log('error');
        console.log(err);
      })
      
  }

  townHallOne(){
    this.http.post<any>(this.url + 'verificationGeneration/sendTownhallOne', {  }).subscribe(data => {
        console.log("success")
      }, err => {
        console.log('error');
        console.log(err);
      })

  }

  townHallMulti(){
    this.http.post<any>(this.url + 'verificationGeneration/sendTownhallMulti', {  }).subscribe(data => {
        console.log("success")
      }, err => {
        console.log('error');
        console.log(err);
      })

  }

  inviteTeam(){
    this.http.post<any>(this.url + 'verificationGeneration/sendTownhallMulti', {  }).subscribe(data => {
        console.log("success")
      }, err => {
        console.log('error');
        console.log(err);
      })

  }
  
  inviteCompany(){
    this.http.post<any>(this.url + 'verificationGeneration/sendTownhallMulti', {  }).subscribe(data => {
        console.log("success")
      }, err => {
        console.log('error');
        console.log(err);
      })

  }

}
