import Swal from 'sweetalert2';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'providers/authentication.service';
import { regexValidators } from 'providers/validator/validator';

export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid ? null : {
    isValid: {
      valid: false
    }
  };
};

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnInit {

  @Input() user: string;
  @Input() token: string;
  @Input() team: string;

  success = false;
  loading = false;
  submitted = false;
  showError = false;

  projectform: FormGroup;

  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(public activeModal: NgbActiveModal,
    private _authService: AuthenticationService,
    private formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.projectform = this.formBuilder.group({
      name: ['', [Validators.required]],
      purpose: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });
  }

  get f() { return this.projectform.controls; }

  async onSubmit() {

    this.submitted = true;
    this.showError = false;
    this.success = false;

    if (this.projectform.invalid) return;

    const projectVm = {
      id: this.user,
      token: this.token,
      project: {
        name: this.f.name.value,
        purpose: this.f.purpose.value,
        description: this.f.description.value,
        team: this.team,
      }
    };

    this.loading = true;

    return await (await this._authService.createProject(projectVm)).subscribe(async data => {

      this.loading = false;
      this.submitted = false;
      this.success = true;

      setTimeout(() => {
        this.success = false;
      }, 15000);

      this.Toast.fire({
        icon: 'success',
        title: 'Your project has been created.'
      })

      setTimeout(() => {
        this.activeModal.close('success')
      }, 3000);

    }, err => {
      this.showError = true;
      let error = 'An error has occurred.'
      if (err.error.message) error = err.error.message;
      this.Toast.fire({
        icon: 'error',
        title: ' Your project has not been created. ' + error + ' Please Try Again.',
      })
      this.loading = false;
      this.submitted = false;
    })
  }
}

