import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import Chart from 'chart.js';
import { ConfigService } from 'providers/config/config.service';
import { AuthenticationService } from 'providers/authentication.service';
import { TEAM, USER } from 'app/pages/coach-area/coach-profile/coach-profile.component';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as schedule from 'node-schedule';
import Swal from 'sweetalert2';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OnboardService } from 'app/onboard.service';


export class ISSUE {
  id: string = '';
  issue: string = '';
  team: string = '';
  creator: string = '';
  creatorAvatar: string = '';
  createdAt: Date = null;
  description: string[] = [''];
  timefromnow?: string = '';
  sender?: boolean = false;
  solved: boolean = false;
}

@Component({
  selector: 'app-coaching-overview',
  templateUrl: './coaching-overview.component.html',
  styleUrls: ['./coaching-overview.component.css']
})

export class CoachingOverviewComponent implements OnInit, AfterViewInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  @ViewChild('coachOverview') coachOverview: ElementRef | undefined;
  closeResult = '';

  ngAfterViewInit(): void {
    if (this.isUserACoach) {
      if (this.tourService.getCoachingOverview() == false) {
        this.open(this.coachOverview);
        this.tourService.setCoachingOverview(true);
      }
    }
  }

  open(content: any) {

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );


  }

  private async getDismissReason(reason: any) {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private subscriptionUser: Subscription;

  id: string = '';
  token: string = '';

  loading: boolean = false;
  isUserACoach: boolean = false;

  meetingSchedules = [];
  meetingId: string = '';

  public teamsRecords: TEAM[] = [];
  public allTeamsRecords: any[] = [];
  public teamsRecordsTemp: TEAM[] = [];
  public selectedTeams = [];

  public allUsersRecords: any[] = [];
  public usersRecords: USER[] = [];
  public usersRecordsTemp: USER[] = [];

  public allIssuesRecords: any[] = [];
  public issuesRecords: ISSUE[] = [];
  public issuesRecordsTemp: ISSUE[] = [];

  today = new Date();
  // now = moment(this.today).fromNow();
  now: string = null;

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  @ViewChild(DatatableComponent) tableTeams: DatatableComponent;
  @ViewChild(DatatableComponent) tableUsers: DatatableComponent;
  @ViewChild(DatatableComponent) tableIssues: DatatableComponent;

  columnsTeams = [
    { name: 'Team' },
    { name: 'Team Health' },
    { name: 'Stage' },
    { name: 'Coach' },

  ];
  columnsUsers = [];
  columnsIssues = [];

  constructor(
    private _authService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
    private readonly _configService: ConfigService,
    private tourService: OnboardService
  ) { }

  async ngOnInit() {
    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.id = user['_id'];
      this.token = user['token'];

      this.isUserACoach = user['role'] == 'Manager' ? true : false;

    });

    // if (!this.isUserACoach) this.router.navigate(['/dashboard']);

    await this.getCoachDashboard();
    await this.getAllCoachSchedules();

    await this._authService.setTeam('');
    await this._authService.setIssue('');
  }

  async getCoachDashboard() {

    const userVm = {
      id: this.id,
      token: this.token,
    };

    this.loading = true;

    await (await this._authService.getCoachDashboard(userVm))
      .pipe(first()).subscribe(
        data => {

          this.allTeamsRecords = [];
          this.teamsRecords = [];

          this.allUsersRecords = [];
          this.usersRecords = [];

          // this.allInformationResourcesRecords = [];
          // this.informationResourcesRecords = [];

          this.allIssuesRecords = [];
          this.issuesRecords = [];

          this.allTeamsRecords = data.teams;
          this.allUsersRecords = data.users;
          // this.allInformationResourcesRecords = data.informationResources;
          this.allIssuesRecords = data.issues;
          console.log(this.allTeamsRecords);

          for (let index = 0; index < this.allTeamsRecords.length; index++) {
            const team = this.allTeamsRecords[index];
            this.teamsRecords.push({
              id: team._id,
              name: team.name,
              description: team.description,
              active: team.active
            })
          }

          this.teamsRecords.sort(function (a, b) {
            if (a.name > b.name) { return -1; }
            if (a.name < b.name) { return 1; }
            return 0;
          });

          this.teamsRecordsTemp = Object.assign([], this.teamsRecords);
          console.log(this.allUsersRecords);

          for (let index = 0; index < this.allUsersRecords.length; index++) {
            const user = this.allUsersRecords[index];
            this.usersRecords.push({
              id: user._id,
              publicid: user.publicid,
              fullName: user['name'] + ' ' + user['surname'],
              name: user['name'],
              surname: user['surname'],
              email: user['email'],
              avatar: this._configService.url + user['avatar']
            })
          }
          console.log(this.usersRecords);
          // for (let index = 0; index < this.allInformationResourcesRecords.length; index++) {
          //   const informationResource = this.allInformationResourcesRecords[index];
          //   this.informationResourcesRecords.push({
          //     id: informationResource.id,
          //     title: informationResource.title,
          //     content: informationResource.content.split("<br>"),
          //   })
          // }

          this.usersRecords.sort(function (a, b) {
            if (a.fullName > b.fullName) { return -1; }
            if (a.fullName < b.fullName) { return 1; }
            return 0;
          });

          this.usersRecordsTemp = Object.assign([], this.usersRecords);

          for (let index = 0; index < this.allIssuesRecords.length; index++) {
            this.allIssuesRecords[index].team = index;
            const issue = this.allIssuesRecords[index];
            this.issuesRecords.push({
              id: issue._id,
              issue: issue.issue,
              team: issue.team,
              creator: issue.creator + ' ' + issue.creator,
              creatorAvatar: this._configService.url + '/resources/assets/avatar/avatar.png',
              createdAt: new Date(),
              // createdAt: issue.createdAt,
              description: issue.description.split("<br>"),
              solved: issue.solved
            })
          }
          console.log(this.allIssuesRecords, this.issuesRecords);

          this.issuesRecords.sort(function (a, b) {
            if (a.createdAt > b.createdAt) { return -1; }
            if (a.createdAt < b.createdAt) { return 1; }
            return 0;
          });

          this.issuesRecordsTemp = Object.assign([], this.issuesRecords);

          this.today = new Date();
          this.now = moment(this.today).fromNow();
          this.loading = false;
        },
        error => {
          // console.log('error', error);
        }
      );
  }

  // Function to handle selecting/deselecting all teams
  onSelectAll(event: any) {
    if (event.target.checked) {
      this.selectedTeams = this.meetingSchedules;
    } else {
      this.selectedTeams = [];
    }
  }

  // Function to handle individual team selection
  onTeamSelect(event: any, team) {
    if (event.target.checked) {
      this.selectedTeams.push(team);
    } else {
      this.selectedTeams = this.selectedTeams.filter((t) => t !== team);
    }
  }

  // Function to open the modal with selected teams
  openModal() {
    // Pass the selected teams to the modal
    const modalRef = this.modalService.open(TimeSelectModalComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      beforeDismiss: () => {
        // Optional: Handle actions before dismissing the modal
        return true;
      },
    });

    // Access the component instance to set properties
    const modalInstance = modalRef.componentInstance as TimeSelectModalComponent;
    modalInstance.selectedTeams = this.selectedTeams; // Pass selected teams to the modal
    modalInstance.id = this.id;
    modalInstance.token = this.token;
    modalInstance.meetingId = this.meetingId;

    modalRef.result.then(async (result) => {
      if (result == 'success') {
        this.Toast.fire({
          icon: 'success',
          title: 'Your request has been sent.'
        })
      }
    });

  }

  async getAllCoachSchedules() {
    const scheduleVm = {
      id: this.id,
      token: this.token,
    };

    await (await this._authService.getAllSchedules(scheduleVm)).subscribe(newData => {
      const scheduleData = newData;

      scheduleData.forEach(schedule => {
        let id = schedule._id;
        this.meetingId = id;
        let teamName = schedule.team.name;
        let proposedSlots = schedule.proposedSlots;
        let slots = [];

        proposedSlots.forEach(element => {
          let date = element[0];
          let time = element[1];

          //coverting to date
          var dateParts = date.split("-");
          var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

          // Getting weekday
          var dayNames = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
          ];
          var dayName = dayNames[dateObject.getDay()];
          let meetingDate = "meetingDate" + teamName;
          let meetingLink = "meetingLink" + teamName;

          slots.push({ "meetingId": id, "teamName": teamName, "weekday": dayName, "time": time, "meetingDate": meetingDate, "meetingLink": meetingLink });
        });

        this.meetingSchedules.push(slots);
      });
      return this.meetingSchedules;
    }, err => {
      console.log('error');
    });
  }
}

@Component({
  selector: 'app-coaching-overview',
  templateUrl: './time-select-modal.html',
  styleUrls: ['./coaching-overview.component.css']
})
export class TimeSelectModalComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
    private readonly _configService: ConfigService,
    public activeModal: NgbActiveModal,
  ) { }

  // Add this property to receive selected teams
  @Input() selectedTeams = [];
  @Input() user: string;
  @Input() token: string;
  @Input() id: string;
  @Input() meetingId: string;

  success = false;
  loading = false;
  submitted = false;
  showError = false;

  acceptSlotForm: FormGroup;

  ngOnInit(): void {
    let userTeams = this.selectedTeams;

    this.acceptSlotForm = new FormGroup({})

    userTeams.forEach(element => {
      this.acceptSlotForm.addControl(element.meetingDate, new FormControl());
      this.acceptSlotForm.addControl(element.meetingLink, new FormControl())
    })
  }


  closeModal() {
    this.activeModal.close();
  }

  get f() { return this.acceptSlotForm.controls; }

  async onSubmit() {

    this.submitted = true;
    this.showError = false;

    if (this.acceptSlotForm.invalid) return;

    let userValues = this.f;
    const allUserInputs = [];

    //Getting the formControl values
    const userInputs = new Map();
    for (const key in userValues) {
      let obj = userValues[key];
      userInputs.set(key, obj.value)
    }

    this.selectedTeams.forEach(team => {
      let dates = []
      dates.push(userInputs.get(team.meetingDate), team.time);
      const acceptSlotVm = {
        id: this.id,
        token: this.token,
        meetingId: team.meetingId,
        acceptedSlots: dates,
        meetingLink: userInputs.get(team.meetingLink),
      };
      allUserInputs.push(acceptSlotVm);
    });

    this.loading = true;

      await (await this._authService.acceptSlot(allUserInputs))
        .pipe(first()).subscribe(data => {
          this.loading = false;
          this.submitted = false;
          this.success = true;
          setTimeout(() => {
            this.activeModal.close('success')
          }, 3000);
        }, err => {
          this.showError = true;
          this.loading = false;
          this.submitted = false;
        })
  }
}
