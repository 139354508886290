import Swal from 'sweetalert2';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'providers/authentication.service';

export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid ? null : {
    isValid: {
      valid: false
    }
  };
};

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css']
})
export class CreateEventComponent implements OnInit {

  @Input() user: string;
  @Input() token: string;
  @Input() challenge: string = null;
  @Input() team: string = null;
  @Input() project: string = null;

  success = false;
  loading = false;
  submitted = false;
  showError = false;

  eventform: FormGroup;

  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(public activeModal: NgbActiveModal,
    private _authService: AuthenticationService,
    private formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.eventform = this.formBuilder.group({
      title: ['', [Validators.required]],
      url: [''],
      addURL: [false, Validators.required],
      allDay: [false, Validators.required],
      // startDate: new FormControl(new Date(), { validators: [Validators.required, DateTimeValidator] }),
      // endDate: new FormControl(new Date(), { validators: [Validators.required, DateTimeValidator] }) 
      startDate: new FormControl('', { validators: [Validators.required, DateTimeValidator] }),
      endDate: new FormControl('', { validators: [Validators.required, DateTimeValidator] })
    }, { updateOn: 'change' });

    this.f['allDay'].valueChanges.subscribe(result => {
      if (!result) {
        this.f['startDate'].setValidators([Validators.required, DateTimeValidator]);
        this.f['startDate'].updateValueAndValidity();
        // this.f['endDate'].setValidators([Validators.required, DateTimeValidator]);
        // this.f['endDate'].updateValueAndValidity();
      } else {
        // this.f['startDate'].setValidators(null);
        // this.f['startDate'].updateValueAndValidity();
        this.f['endDate'].setValidators(null);
        this.f['endDate'].updateValueAndValidity();
      }
    });

    this.f['addURL'].valueChanges.subscribe(result => {
      if (result) {
        this.f['url'].setValidators(Validators.required);
        this.f['url'].updateValueAndValidity();
      } else {
        this.f['url'].setValidators(null);
        this.f['url'].updateValueAndValidity();
      }
    });
  }

  get f() { return this.eventform.controls; }

  async onSubmit() {

    this.submitted = true;
    this.showError = false;
    this.success = false;

    if (this.eventform.invalid) return;

    const eventVm = {
      id: this.user,
      token: this.token,
      title: this.f.title.value,
      startDate: this.f.startDate.value,
      endDate: this.f.endDate.value,
      allDay: this.f.allDay.value,
      url: this.f.url.value,
      challenge: this.challenge,
      team: this.team,
      project: this.project
    };

    this.loading = true;

    return await (await this._authService.createCalendarEvent(eventVm)).subscribe(async data => {

      this.loading = false;
      this.submitted = false;
      this.success = true;

      setTimeout(() => {
        this.success = false;
      }, 15000);

      this.Toast.fire({
        icon: 'success',
        title: 'Your event has been published.'
      })

      setTimeout(() => {
        this.activeModal.close('success')
      }, 3000);

    }, err => {
      this.showError = true;
      let error = 'An error has occurred.'
      if (err.error.message) error = err.error.message;
      this.Toast.fire({
        icon: 'error',
        title: ' Your event has not been published. ' + error + ' Please Try Again.',
      })
      this.loading = false;
      this.submitted = false;
    })
  }
}

