import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { StoreProvider } from 'providers/store/store';
import { regexValidators } from 'providers/validator/validator';
import { Subscription } from 'rxjs';
import { EscalateModalComponent } from '../escalate-modal/escalate-modal.component';
import { ModalsComponent, TEAM } from '../modals.component';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private storage: StoreProvider,
        private router: Router,
        private modalService: NgbModal,
        private readonly _configService: ConfigService,
        public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
  }

  // confirmDelete(){
  //   this.modalService.open(DeleteConfirmComponent, { size: 'md', backdrop: 'static', centered: true })
  // }

  cancelDelete() {
    this.activeModal.close();
    this.modalService.open(ModalsComponent, { size: 'lg', backdrop: 'static', centered: true })
  }

  confirmDelete(){
    this.activeModal.close();
    this.modalService.open(DeleteModalConfirmComponent, { size: 'md', backdrop: 'static', centered: true })
  }

  onCloseClick() {
    this.activeModal.close();
  }

}

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-confirm.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalConfirmComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private storage: StoreProvider,
        private router: Router,
        private modalService: NgbModal,
        private readonly _configService: ConfigService,
        public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
  }

  // confirmDelete(){
  //   this.modalService.open(DeleteConfirmComponent, { size: 'md', backdrop: 'static', centered: true })
  // }

  closeModal() {
    this.activeModal.close();
  }

  undoDelete(){

  }

  onCloseClick() {
    this.activeModal.close();
  }

}
