import { Component, OnInit, Renderer2, ViewChild, ElementRef, Directive } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import { ROUTES } from '../sidebar/sidebar.component';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { NotificationsService } from 'app/notifications.service';
import { MsalService } from '@azure/msal-angular';

var misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    private _router: Subscription;
    public open: boolean = false;

    @ViewChild("navbar-cmp", { static: false }) button;

    private subscriptionUser: Subscription;

    user = {
        id: '',
        publicid: '',
        token: '',
        role: '',
        fullName: '',
        name: '',
        email: '',
        avatar: '',
        emailVerification: null
    };

    constructor(
        location: Location,
        private renderer: Renderer2,
        private element: ElementRef,
        private router: Router,
        private _authService: AuthenticationService,
        private readonly _configService: ConfigService,
        private notificationService: NotificationsService,
        private _msalService: MsalService
    ) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    isMobile: boolean = false;
    notificationList: any = [];

    async ngOnInit() {

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }

        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });

        this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
            let user: any[] = value ? JSON.parse(value) : {}
            this.user.id = user['_id'];
            this.user.token = user['token'];
            this.user.name = user['name'];
            this.user.fullName = user['name'] + ' ' + user['surname'];
            this.user.email = user['email'];
            this.user.role = user['role'];
            this.user.publicid = user['publicid'];
            this.user.emailVerification = user['emailVerification'];

            // if (this.user.emailVerification) {
            //     this.privilege = user['privilege'];
            //     if (user['role'] == 'Admin') this.admin = true;
            //     else this.admin = false;
            // }

            // this.showUnverifiedEmail = !this.user.emailVerification;
            this.user.avatar = this._configService.url + user['avatar'];

            // await this.notificationService.getUser();
            // this.notificationService.getNotifications();

        });


        const notificationVm = {
            id: this.user.id,
            token: this.user.token,
            // recipients: recip
        };

        await (await this._authService.getNotifications(notificationVm)).subscribe(res => {
            // console.log(res)
            this.notificationList = res;
            this.getNotificationOwner();
        })
    }

    async getNotificationOwner() {

        // console.log(this.notificationList)

        for (let i = 0; i <= this.notificationList.length - 1; i++) {

            let userVm = {
                id: this.user.id,
                token: this.user.token,
                user: this.notificationList[i].creator
            }

            await (await this._authService.getUserProfileNoPub(userVm)).subscribe(res => {
                // console.log(res)
                this.notificationList[i].creator = res.user.name + " " + res.user.surname;
            })
        }

        this.notificationList.sort((a: any, b: any) => {
            return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
        });

    }

    ngOnDestroy() {
        this.subscriptionUser.unsubscribe();
    }

    ngAfterViewInit() {
        this.minimizeSidebar();
    }

    backButton() {
        this.location.back();
    }

    async logout() {
        await this._authService.logout();
        if (this._msalService.instance.getActiveAccount() != null){
            this._msalService.logoutPopup();
        }
        this.router.navigate(['/login']);
    }


    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    isMobileMenu() {
        if (window.outerWidth < 991) {
            return false;
        }
        return true;
    }

    sidebarOpen() {
        var toggleButton = this.toggleButton;
        var html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            // toggleButton.classList.add('toggled');
        }, 500);
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
        if (window.innerWidth < 991) {
            mainPanel.style.position = 'fixed';
        }
        html.classList.add('nav-open');
        this.sidebarVisible = true;
    }

    sidebarClose() {
        var html = document.getElementsByTagName('html')[0];
        // this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

        if (window.innerWidth < 991) {
            setTimeout(function () {
                mainPanel.style.position = '';
            }, 500);
        }
    }

    sidebarToggle() {
        // var toggleButton = this.toggleButton;
        // var body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible == false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        for (var item = 0; item < this.listTitles.length; item++) {
            var parent = this.listTitles[item];
            if (parent.path === titlee) {
                return parent.title;
            } else if (parent.children) {
                // var children_from_url = titlee.split("/")[2];
                var children_from_url = titlee.split("/")[1];
                for (var current = 0; current < parent.children.length; current++) {
                    if (parent.children[current].path === children_from_url) {
                        return parent.children[current].title;
                    }
                }
            }
        }
        return 'Dashboard';
    }

    getPath() {
        // console.log(this.location);
        return this.location.prepareExternalUrl(this.location.path());
    }

    showNotifications: boolean = false;
    notifications() {
        // console.log("here")
        if (this.showNotifications == false) {
            this.showNotifications = true;
        } else if (this.showNotifications == true) {
            this.showNotifications = false;
        }
    }

    showNotificationsMob: boolean = false;
    notificationsMob() {
        console.log("shown!")
        if (this.showNotificationsMob == false) {
            this.showNotificationsMob = true;
        } else if (this.showNotificationsMob == true) {
            this.showNotificationsMob = false;
        }
    }
}
