export const teamCharterCoach = [
    {
        "question": "Who will lead the team and who will be deputy leader?",
        "description": "It is important to agree on a Team Leader from the outset and to agree a deputy in the case of absence/sickness.",
        "answer": "Based on the previous working experiences identified when we shared our profile with each other in Part 1 Section 5, we have decided that we will start with:AA as Team Leader.CC as Deputy Team Leader.We do intend to rotate the leadership functions (see Part 2, item 3 below)"
    },
    {
        "question": "What are the other roles which will be performed on this project and who is responsible?",
        "description": "It is important that all team members fully understand and accept their roles and responsibilities. In assigning roles, it is important to fully utilize the skills and experience within the team. ",
        "answer": "We shared our profile with each other (see Part 1 Section 5). Our project involves App Development. We agreed that our roles within the team are: AA Business AnalystBB Designer CC Designer DD Business Analyst EE Coder/Developer FF Social Marketing EE will support DD in requirements analysis. CC and BB will pair for design work with AA helping on wireframing (has some experience here).All team members will help with testing of App coordinated by DD."
    },
    {
        "question": "How will the team share leadership? ",
        "description": "The best teams do not rely on one sole person to lead the team but instead share responsibility for leadership.",
        "answer": "AA will be the Team Leader for the first 3 months.CC Deputy Leader.Roles will then rotate if CC feels capable.AA will take the lead on issue resolution – strong negotiator.CC will take the lead on client updates – excellent communication skills.FF will lead sub-group on Market Research on competitor Apps.DD will lead requirements gathering and analysis with support of AA.DD will prepare and organize Testing."
    },
    {
        "question": "What does the team expect from their leaders?",
        "description": "There are distinctive styles of leadership, and the team needs to consider the type of leadership that will get the best from them as a team.",
        "answer": "We expect the Leader to be strong, clear of our purpose, and supporting of that purpose.We want the leader to be empathetic to the nuances of DEI within the team, whilst remaining resolute in their leadership role. Most of all we want the leader to be open, honest, and approachable, particularly in times of personal challenge or project strain."
    },
    {
        "question": "What technologies will you use to collaborate, share ideas, and store your work?",
        "description": "The proper use of technology tools enables productive team collaboration. It is essential to only use tools which are approved by the sponsoring organization.",
        "answer": "We agreed to use Slack for communication, which is integrated with Google Drive, Google Calendar & Trello. We will use Google Drive to collaborate on documents and store files and artefacts. Zoom will be used for face-to-face meetings. Meetings to be scheduled in a dedicated shared Google Calendar. Gmail will be used for meetings and app notifications. Trello will be used as our main team sharing platform, any updates or changes, tasks completed, new tasks will be reflected there. Team members will be responsible for updating their own Trello activity and monitoring team activity."
    },
    {
        "question": "How will you ensure that all the team members have the necessary access rights to the agreed collaboration tools?",
        "description": "Restricted access may create an obstacle to team collaboration.",
        "answer": "Having initially set up all the team on the approved collaboration tools, we realized that FF does not have access to Trello. An access request has been approved and submitted to the IT Helpdesk.All other team members have access to all collaboration tools."
    },
    {
        "question": "How does the team plan to use AI powered Chatbots including ChatGPT in completing their work?",
        "description": "The team can use AI technology to make collaboration more productive.",
        "answer": "We will use ChatGPT to supplement and validate our own market research work.We will use ChatGPT for brainstorming purposes but only AFTER we have exhausted our own suggestions.We may also use ChatGPT for User Test Plan/Test Scripts."
    },
    {
        "question": "How does your team plan to address any skills gaps in the use of collaboration tools?",
        "description": "It is important that teams fully exploit available collaboration technology for maximum efficiency.",
        "answer": "DD and EE are ‘Super Users’ on Collaboration Tools.They will prepare a short refresher course for team members and provide help should anyone need it."
    },
    {
        "question": "How does the team plan to ensure that they have reliable connectivity to enable successful collaboration?",
        "description": "Poor connectivity can be frustrating for the person affected and for their colleagues. It can deprive the team of important contributions.",
        "answer": "We perform a weekly speed test using a tool called Ookla to see how connectivity is for each of us.In general, our ping, download and upload speed is much the same for each of these tests and proves to be more than fit for purpose.Currently, there are no connectivity concerns, but we continue to test weekly.Where someone anticipates connectivity issues then they are responsible for arranging an alternative to ensure full engagement at meetings."
    },
    {
        "question": "When will you hold your meetings?",
        "description": "Regular team meetings help to align the team in the same direction and create a sense of ‘connection’ which is important in a remote working team.",
        "answer": "We will commence with two online meetings per week. One on Monday between 7pm and 8pm and between 8pm and 9pm Wednesday evenings after team coaching sessions.After a month we will review and reduce it to one per week on Wednesday.As deadlines approach, we may decide to increase frequency as appropriate."
    },
    {
        "question": "What happens before each meeting? How will you arrange a meeting? How will you gather agenda items?",
        "description": "Well organized and productive meetings increase team morale and help ensure continued commitment.",
        "answer": "We agreed that the focus of our team meetings should be on planning and decision making. Feedback on circulated documents/artefacts should be done in advance of the meeting so as not to waste valuable time.The regular weekly meeting will be a fixed agenda (with time allocated to items). Trello will be used to gather up agenda items as tasks on the project board. "
    },
    {
        "question": "What if someone is sick or has a holiday booked?",
        "description": "Absences from your team can be disruptive but are sometimes unavoidable. You need to specify how absences should be notified in order to minimize disruption.",
        "answer": "If one or more members cannot make the assigned meeting, a simple group email is to be sent stating that they cannot attend. Preferably notification will be sent 24 hours in advance.Holidays or planned absences will be considered in assigning the project tasks and delivery deadlines. If needed, tasks may be shared between team members. In all instances the team are committed to supporting each other."
    },
    {
        "question": "What happens at your meetings? Will there be an agreed Chair/Facilitator, note taker and timekeeper for each meeting? ",
        "description": "There tends to be a limited amount for online meetings so it is important that they are well organized otherwise people will stop attending.",
        "answer": "The Team Leader will chair our meetings with the Deputy updating Trello with agreed actions and decisions while tracking time. When the Team Lead is absent then the Deputy will take the chair and FF will update Trello.The Team Leader starts the meeting with a round table where each member provides an update on their tasks: progress, roadblocks etc.Once the current state of play is established, existing tasks and additional tasks are assigned/reassigned as necessary with agreed deadlines."
    },
    {
        "question": "What are your main ground rules to guide behaviors at your meetings?",
        "description": "Ground rules provide the safe space for diversity to thrive and provide a basis for intervening when behaviors are unacceptable. ",
        "answer": "Our Ground Rules support our Shared Values (Part 1 Section 2) Be well prepared and reliably connected (broadband). Turn up on time and stay for the full meeting. Be open and honest – we want buy in. Be respectful to others’ opinions. Keep perspective remembering our CSoP (Part 1 Section 3)."
    },
    {
        "question": "What happens after a meeting? How will you send out tasks? Who is responsible?",
        "description": "It is important that actions, decisions, tasks are captured promptly in your agreed collaboration tools. People like to see follow-through.",
        "answer": "The Deputy Leader updates Trello during the meeting with agreed actions. Once the meeting is over, these actions, owners, and deadlines are circulated to all team members by the DL. The Team Leader/Deputy control requests for changes to agreed actions. "
    },
    {
        "question": "How do we set expectations for engagement and contribution from the outset of the project?",
        "description": "Setting expectations helps to prevent a lack of engagement and contribution from individual team members. ",
        "answer": "We shared experiences on teams that we were involved in where not everyone pulled their weight. We all found this demoralizing and are determined to avoid this situation on our team. We expect everyone to be equally committed and motivated and share the workload fairly."
    },
    {
        "question": "What happens if a team member is not engaging or contributing to the team or project?",
        "description": "Failure to tackle a lack of engagement or contribution from an individual team member can demoralize other team members and impact their performance.",
        "answer": "Our discussion around CSoP should help to ensure that this situation will not arise. We are all equally motivated to make this project a success. Being paired for tasks, attendance at meetings and regular updating of Trello should help us keep track of contribution and engagement.        If one of us is falling behind or is struggling, then they just have to ask for help. Where all this fails then the Team Leader (TL) needs to engage and remind the Team Member of their commitment to our agreed Team Charter. If issues remain, then the TL escalates to line manager for resolution. If the issue is outside the control of the Team Member, then the issue may be escalated using the proper procedure."
    },
    {
        "question": "How will you make decisions?",
        "description": "Involving everyone in the decision-making process increases team commitment and buy in.",
        "answer": "We will make every effort to obtain consensus in accordance with our shared values and CSoP (Part 1 Sections 2-3). Where consensus is not possible, then the Team Lead will have the deciding vote. We acknowledge that this approach is necessary particularly when we are up against a tight deadline."
    },
    {
        "question": "How will you ensure that your decisions are consistent with your agreed values and common sense of purpose?",
        "description": "Making decisions which are consistent with our purpose and values ensure that we move in the direction that the team wants to go in. This is particularly important where projects are driven by corporate social responsibility.",
        "answer": "Most of all we encourage and value each person’s opinion and contribution. Ideally, we want everyone to be committed to our plans/decisions and to support their implementation. "
    },
    {
        "question": "How do you propose to avoid conflict? How do you plan to manage conflict should it arise?",
        "description": "Failure to manage conflict can demoralize a team and put the project at risk.",
        "answer": "Our Best Practices (Part 1 Section 7) focus on giving each other positive feedback to aid learning. Where conflict arises, we will remind each other of our shared values and CSoP (Part 1 Section 2-3).The Team Leader may consult with our Team Coach on the best approach should serious conflict arise. Issues may need to be escalated to line managers for resolution. We will support the Team Leader in their efforts to resolve serious conflict. "
    },
    {
        "question": "How does the team plan to review and improve their effectiveness? ",
        "description": "It is one thing to commit to a Team Charter. It is quite another to ‘live it.’  The best teams continually look for ways to improve team effectiveness.",
        "answer": "As a team, we review our effectiveness in a monthly meeting where we specifically discuss the Team Charter and how we feel we are performing against everything we signed up to.We are committed to continually improving our effectiveness as a team. Being effective is about doing the Right Things.We agree that the Appreciative Review is our opportunity as a team to reflect on the ‘Right Things’ that we are already doing and how we might improve. We will try to identify and implement the 1-2 changes that will ‘Make a Real Difference’ in accordance with our team motto (Part 1 Section 3-4)."
    },
    {
        "question": "Are there GDPR, Compliance or Audit requirements that must be adopted by the team in the management and version control of documents?",
        "description": "Project teams must adhere to data protection requirements otherwise they may leave the organization open to a data breach or potential regulatory penalties and fines.",
        "answer": "We will use Google Drive to collaborate on documents and store files and artefacts. GDPR requirements govern the use of Google Drive, and we are committed to operating in compliance with these."
    },
    {
        "question": "How will you name documents?",
        "description": "This is particularly important so everyone can find documents and know they are working from the latest version.",
        "answer": "Trello will be used to update task completion and notify colleagues of the availability of new versions of documents and artefacts for review."
    },
]