import { StoreProvider } from './store/store';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config/config.service';
import { map } from "rxjs/operators";
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export const USER_NAME: string = 'currentUser';
export const ADMIN_TEAM_ID: string = 'admin_team_id';
export const TEAM_ID: string = 'team_id';
export const CHALLENGE_ID: string = 'challenge_id';
export const CHALLENGE_NAME: string = 'challenge_name';
export const IDEA_ID: string = 'idea_id';
export const PROJECT_ID: string = 'project_id';
export const ADMIN_COMPANY_ID: string = 'admin_company_id';
export const SURVEY_ID: string = 'survey_id';
export const SURVEY_RESPONSE_ID: string = 'survey_response_id';
export const ISSUE_ID: string = 'issue_id';
export const CMG_ID: string = 'cmg_id';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // Variable for determining if a user is logged in or not
  private authStatus$ = new BehaviorSubject<boolean>(false);
  private user = new BehaviorSubject<string>('');
  private notifications = new BehaviorSubject<string>('');

  userID: string;
  tokenID: string;

  constructor(
    private http: HttpClient,
    private storage: StoreProvider,
    private router: Router,
    private modalService: NgbModal,
    private readonly _configService: ConfigService) {

    this.authUserChange.subscribe(value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.userID = user['_id'];
      this.tokenID = user['token'];
    })
  }

  async checkAuthorization() {

    const tokenVm = {
      id: this.userID,
      token: this.tokenID,
    };

    this.setIsAuthorized(false);
    await this.http.post<any>(this._configService.url + 'user/token', tokenVm)
      .subscribe(async user => {
        if (user && user.token) {
          // this.setToken(user.token);
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser(JSON.stringify(''));
          this.setIsAuthorized(true);
        }
        //return user;

        // if (user && user.token) {
        //   // if (await this.getIsItMasterLogin()) {
        //   //   // user.user.role = 'User';
        //   //   // user.user.privilege.companyAdministrator = false;
        //   // }
        //   this.setUserLogin(JSON.stringify(user.user));
        //   this.setIsAuthorized(true);
        // }
      }, async err => {
        console.log('err - token', err);
        await this.logout();
        this.router.navigate(['/login']);
      });
  }

  // An observable that will change when our user has logged in
  get authStatusChange() {
    return this.authStatus$.asObservable();
  }

  get authUserChange() {
    return this.user.asObservable();
  }

  get authUserNotifications() {
    return this.notifications.asObservable();
  }

  // Set the is authorised
  setIsAuthorized(value: boolean) {
    this.authStatus$.next(value);
  }

  // Get the value of the user authorisation
  isAuthorized() {
    return this.authStatus$.value;
  }

  // Set the user
  async setItem(key: string, item: string): Promise<void> {
    await this.storage.setItem(key, item);
  }

  // Get the user
  async getItem(key: string): Promise<any> {
    return await this.storage.getItem(key);
  }

  // Clear the user
  async clearItem(key: string) {
    return await this.storage.remove(key);
  }

  setUserLogin(user: any): void {
    this.user.next(user);
  }

  setNotification(notifications: any): void {
    this.notifications.next(notifications);
  }

  async setAdminTeam(team: string): Promise<void> {
    await this.storage.setItem(ADMIN_TEAM_ID, team);
  }

  async getAdminTeam(): Promise<string> {
    return await this.storage.getItem(ADMIN_TEAM_ID);
  }

  async setTeam(team: string): Promise<void> {
    await this.storage.setItem(TEAM_ID, team);
  }

  async getTeam(): Promise<string> {
    return await this.storage.getItem(TEAM_ID);
  }

  async setIssue(issue: string): Promise<void> {
    await this.storage.setItem(ISSUE_ID, issue);
  }

  async getIssue(): Promise<string> {
    return await this.storage.getItem(ISSUE_ID);
  }

  async setChallenge(challenge: string): Promise<void> {
    await this.storage.setItem(CHALLENGE_ID, challenge);
  }

  async setChallengeName(challenge: string): Promise<void> {
    await this.storage.setItem(CHALLENGE_NAME, challenge);
  }


  async getChallenge(): Promise<string> {
    return await this.storage.getItem(CHALLENGE_ID);
  }

  async getChallengeName(): Promise<string> {
    return await this.storage.getItem(CHALLENGE_NAME);
  }

  async setIdea(idea: string): Promise<void> {
    await this.storage.setItem(IDEA_ID, idea);
  }

  async getIdea(): Promise<string> {
    return await this.storage.getItem(IDEA_ID);
  }

  async setSurvey(survey: string): Promise<void> {
    await this.storage.setItem(SURVEY_ID, survey);
  }

  async getSurvey(): Promise<string> {
    return await this.storage.getItem(SURVEY_ID);
  }

  async setSurveyResponse(response: string): Promise<void> {
    await this.storage.setItem(SURVEY_RESPONSE_ID, response);
  }

  async getSurveyResponse(): Promise<string> {
    return await this.storage.getItem(SURVEY_RESPONSE_ID);
  }

  async setAdminCompany(company: string): Promise<void> {
    await this.storage.setItem(ADMIN_COMPANY_ID, company);
  }

  async getAdminCompany(): Promise<string> {
    return await this.storage.getItem(ADMIN_COMPANY_ID);
  }

  async setProject(project: string): Promise<void> {
    await this.storage.setItem(PROJECT_ID, project);
  }

  async getProject(): Promise<string> {
    return await this.storage.getItem(PROJECT_ID);
  }

  async setCmg(cmg: string): Promise<void> {
    await this.storage.setItem(CMG_ID, cmg);
  }

  async getCmg(): Promise<string> {
    return await this.storage.getItem(CMG_ID);
  }

  // Get the user
  getUser(): any {
    return this.user.value;
  }

  // Clear the user
  async clearUser() {
    return await this.storage.remove(USER_NAME);
  }

  getAPI() {
    return this.http.get<any>(this._configService.url);
  }

  async login(loginVm) {
    this.setIsAuthorized(false);
    return await this.http.post<any>(this._configService.url + 'user/login', loginVm)
      .pipe(map(async user => {
        if (user && user.token) {
          // this.setToken(user.token);
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await await this.storage.setItem('currentUser', JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser(JSON.stringify(''));
          this.router.navigate(['/dashboard']);
          this.setIsAuthorized(true);
        }
        return user;
      }));
  }

  async ssoLogin(loginVm) {
    this.setIsAuthorized(false);
    return await this.http.post<any>(this._configService.url + 'user/loginSso', loginVm)
      .pipe(map(async user => {
        if (user && user.token) {
          // this.setToken(user.token);
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await await this.storage.setItem('currentUser', JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser(JSON.stringify(''));
          this.router.navigate(['/dashboard']);
          this.setIsAuthorized(true);
        }
        return user;
      }));
  }

  async token(tokenVm) {
    this.setIsAuthorized(false);
    return await this.http.post<any>(this._configService.url + 'user/token', tokenVm)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          user.user.isAuthorized = true;
          this.setUserLogin(JSON.stringify(user.user));
          // this.setUser(JSON.stringify(''));
          this.router.navigate(['/dashboard']);
          this.setIsAuthorized(true);
        }
        return user;
      }));
  }

  async EditUser(userVm: any) {
    return await this.http.post<any>(this._configService.url + 'user/editUser/', userVm)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async EditUserBio(userVm: any) {
    return await this.http.post<any>(this._configService.url + 'user/editUserBio/', userVm)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async joinSuggestedTeam(userVm: any) {
    return await this.http.post<any>(this._configService.url + 'team/joinSuggestedTeam/', userVm)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async joinChallenge(userVm: any) {
    return await this.http.post<any>(this._configService.url + 'challenge/joinChallenge/', userVm)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async leaveChallenge(userVm: any) {
    return await this.http.post<any>(this._configService.url + 'challenge/leaveChallenge/', userVm)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async joinTeam(userVm: any) {
    return await this.http.post<any>(this._configService.url + 'team/joinTeam/', userVm)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async leaveTeam(userVm: any) {
    return await this.http.post<any>(this._configService.url + 'team/leaveTeam/', userVm)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async tokenDashboard(tokenVm) {
    this.setIsAuthorized(false);
    return await this.http.post<any>(this._configService.url + 'user/token', tokenVm)
      .pipe(map(async user => {
        if (user && user.token) {
          // this.setToken(user.token);
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser(JSON.stringify(''));
          this.setIsAuthorized(true);
        }
        return user;
      }));
  }

  async register(register: any) {
    return await this.http.post<any>(this._configService.url + 'user/register', register)
      .pipe(map(data => {
      }));
  }

  async createCalendarEvent(calendarVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'calendar/createEvent', calendarVm)
      .pipe(map(data => {
      }));
  }

  async createProject(projectVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'project/create', projectVm)
      .pipe(map(data => {
      }));
  }

  async editProject(projectVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'project/editProject', projectVm)
      .pipe(map(data => {
      }));
  }

  async saveTeamCharter(teamCharterVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'teamCharter/saveTeamCharter', teamCharterVm)
      .pipe(map(data => {
      }));
  }

  async signTeamCharter(teamCharterVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'teamCharter/signTeamCharter', teamCharterVm)
      .pipe(map(data => {
      }));
  }

  async updateProject(projectVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'project/updateProject', projectVm)
      .pipe(map(data => {
      }));
  }

  async getAllCompaniesEvents(calendarVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'calendar/getAllCompaniesEvents', calendarVm);
  }

  async getAllChallengeEvents(calendarVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'calendar/getAllChallengeEvents', calendarVm);
  }

  async getAllTeamEvents(calendarVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'calendar/getAllTeamEvents', calendarVm);
  }

  async getAllProjectEvents(calendarVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'calendar/getAllProjectEvents', calendarVm);
  }

  async createIdea(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/create', ideaVm)
      .pipe(map(data => {
        this.setIdea(data._id);
      }));
  }

  async createPost(postVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'post/create', postVm)
      .pipe(map(data => {
      }));
  }

  async editIdea(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/edit', ideaVm)
      .pipe(map(data => {
      }));
  }

  async addLike(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/likeIdea', ideaVm)
      .pipe(map(data => {
      }));
  }

  async removeLike(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/unlikeIdea', ideaVm)
      .pipe(map(data => {
      }));
  }

  async deleteIdea(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/delete', ideaVm)
      .pipe(map(data => {
      }));
  }

  async flaggingIdea(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/flagging', ideaVm)
      .pipe(map(data => {
      }));
  }

  async ratingIdeas(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/ratingIdeas', ideaVm);
  }

  async rankingIdeas(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/rankingIdeas', ideaVm);
  }

  async getAllRatedIdeas(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/getAllRatedIdeas', ideaVm);
  }

  async createFaq(faq: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'faq/create', faq)
      .pipe(map(data => {
      }));
  }

  async sendEmail(sendEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'newsletter/sendEmail', sendEmailVm)
      .pipe(map(data => {
      }));
  }

  async getAllEmails(sendEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'newsletter/getAllEmails', sendEmailVm);
  }

  async deleteEmail(sendEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'newsletter/deleteContactUs', sendEmailVm);
  }

  async createTeam(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/create', teamVm)
      .pipe(map(data => {
      }));
  }

  async createChallenge(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/create', challengeVm)
      .pipe(map(data => {
      }));
  }

  async createSurveyGlobal(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'survey/createSurveyGlobal', surveyVm)
  }

  async createSurvey(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'survey/createSurvey', surveyVm)
  }

  async createCharter(charterVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'emp-coach-team-charter/create', charterVm)
  }

  async respondSurvey(responseVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'response/respondSurvey', responseVm)
  }

  async editSurvey(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'survey/editSurvey', surveyVm)
  }

  async createChallengeUploadMultimedia(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'multimedia/createChallengeUploadMultimedia', formData)
  }

  async uploadMultimedia(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'multimedia/uploadMultimedia', formData)
  }

  async uploadProfilePicture(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'multimedia/uploadProfilePicture', formData)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async editTeam(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/editTeam', teamVm)
      .pipe(map(data => {
      }));
  }

  async editChallenge(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/editChallenge', challengeVm)
      .pipe(map(data => {
      }));
  }

  async createCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'company/create', companyVm)
      .pipe(map(data => {
      }));
  }

  async editCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'company/editCompany', companyVm)
      .pipe(map(data => {
      }));
  }

  async createCategory(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'category/create', categoryVm)
      .pipe(map(data => {
      }));
  }

  async editCategory(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'category/editcategory', categoryVm)
      .pipe(map(data => {
      }));
  }

  async editUserRole(userVm: any) {
    await this.checkAuthorization();
    return await this.http.put<any>(this._configService.url + 'user/role/', userVm)
      .pipe(map(data => {
      }));
  }

  async editUserPrivilege(userVm: any) {
    await this.checkAuthorization();
    return await this.http.put<any>(this._configService.url + 'user/privilege/', userVm)
      .pipe(map(data => {
      }));
  }

  async addImage(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'multimedia/addImage', formData);
  }

  async SystemProfilePicture(id: string, userVm: any) {
    await this.checkAuthorization();
    return await this.http.put<any>(this._configService.url + 'user/SystemProfilePicture/' + id, { userVm })
      .pipe(map(async user => {
        if (user && user.token) {
          // this.setToken(user.token);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async EditPassword(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/password', userVm)
      .pipe(map(data => {
      }));
  }

  async EditProfilePicture(id: string, formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'multimedia/profilepicture/' + id, formData)
      .pipe(map(async user => {
        if (user && user.token) {
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async getAllContactUsMessage(contactUsVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'newsletter/contactUs', contactUsVm);
  }

  async getAllNewsletter(newsletterVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'newsletter/newsletter', newsletterVm);
  }

  async getAllFaq() {
    await this.checkAuthorization();
    return await this.http.get(this._configService.url + 'faq');
  }

  async getAdminAllUsers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/getAdminAllUsers', userVm);
  }

  async getCompanyAllUsers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/getCompanyAllUsers', userVm);
  }

  async getAdminAllTeams(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/getAdminAllTeams', teamVm);
  }

  async getAllMyTeams(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/getAllMyTeams', teamVm);
  }

  async getCompanyAllTeams(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/getCompanyAllTeams', teamVm);
  }

  async getAllChallenges(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/getAll', challengeVm);
  }

  async getAllChallengeVersions(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge-versions/getAll', challengeVm);
  }


  async initiateChallengeImplementation(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/initiateChallengeImplementation', challengeVm);
  }

  async getAllCompanyChallenges(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/getAllCompanyChallenges', challengeVm);
  }

  async getAllCompanyPreviousChallenges(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/getAllCompanyPreviousChallenges', challengeVm);
  }

  async getAllAdminCompanyChallenges(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/getAllAdminCompanyChallenges', challengeVm);
  }

  async getMyChallenges(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/getMyChallenges', challengeVm);
  }

  async getManagerAllTeams(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/getManagerAllTeams', teamVm);
  }

  async getAllTeamNames(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/getAllTeamNames', teamVm);
  }

  async getUserTeams(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/getUserTeams', teamVm);
  }

  async getMySchedules(scheduleVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'meeting-schedule/getMySchedules', scheduleVm);
  }

  async getAllSchedules(scheduleVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'meeting-schedule/getAll', scheduleVm);
  }

  async populateSkillsDatabase(databaseVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'skill/populateDatabase', databaseVm)
  }

  async getSkills(skillsVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'skill/getAll', skillsVm)
  }

  async getAllSurveysGlobal(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'survey/getGlobalAll', surveyVm);
  }

  async getAllSurveyResponses(responseVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'response/getAllSurveyResponses', responseVm);
  }

  async getPreviousMyChallenges(challengeVm: any) {
    await this.checkAuthorization();
    let params = new HttpParams();
    params = params.append('id', challengeVm.id);
    params = params.append('token', challengeVm.token);
    return await this.http.get<any>(this._configService.url + 'challenge/getPreviousMyChallenges', {'params': params} );
  }

  async getAllCompanies(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'company/getAll', companyVm);
  }

  async getAllCompanyIdentifiers(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'company/getAllIdentifiers', companyVm);
  }

  async getAllCompaniesAdmin(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'company/getAllAdmin', companyVm);
  }

  async getAdminCompanyInfo(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'company/getAdminCompanyInfo', companyVm);
  }

  async getCompanyName(companyVm: any) {
    return await this.http.post<any>(this._configService.url + 'company/getCompanyName', companyVm);
  }

  async getAdminCompanyInfoByID(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'company/getAdminCompanyInfoByID', companyVm);
  }

  async getAllCategories(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'category/getAll', categoryVm);
  }

  async getAllCategoriesByType(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'category/getAllByType', categoryVm);
  }

  async getAdminTeamProfile(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/getAdminTeamProfile', teamVm);
  }

  async getSurveyDetails(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'survey/getSurveyDetails', surveyVm);
  }

  async getUserResponseSurveyDetails(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'survey/getUserResponseSurveyDetails', surveyVm);
  }

  async getSurveyResponseDetails(respondVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'response/getSurveyResponseDetails', respondVm);
  }

  async getTeamProfile(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/getTeamProfile', teamVm);
  }
  async createEmployeeProfileFit(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'employee-profile-fit/createEmployeeProfileFit', teamVm);
  }

  async disapproveProjects(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/approve', ideaVm);
  }

  async closeIssue(issueVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'issue/closeIssue', issueVm);
  }

  async getUserProfile(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/getUserProfile', userVm);
  }

  async getUserProfileNoPub(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/getUserProfileNoPublic', userVm);
  }

  async getCoachProfile(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/getCoachProfile', userVm);
  }

  async getCoach(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/getCoach', userVm);
  }

  async getCoachDashboard(coachVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'coach/getCoachDashboard', coachVm);
  }

  async getIssueChain(issueVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'issue/getIssueChain', issueVm);
  }

  async createInformationResources(InformationResourceVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'coach/createInformationResources', InformationResourceVm);
  }

  async createParentIssue(issueVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'issue/createParentIssue', issueVm);
  }

  async createChildIssue(issueVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'issue/createIssueResponse', issueVm);
  }

  async createIssueResponse(issueVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'issue/createIssueResponse', issueVm);
  }

  async acceptSlot(slotVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'meeting-schedule/acceptSlot', slotVm);
  }

  async getChallengeProfile(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/getChallengeProfile', challengeVm);
  }

  async getChallengeProfilePID(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/getChallengeProfilePID', challengeVm);
  }

  async createChallengeideaTeams(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/createChallengeideaTeams', challengeVm);
  }

  async createChallengeTeams(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/createChallengeTeams', challengeVm);
  }

  async getProjectProfile(projectVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'project/getProjectProfile', projectVm);
  }

  async getTeamsCharter(teamCharterVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'teamCharter/getTeamsCharter', teamCharterVm);
  }

  async getEvent(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'calendar/getEvent', challengeVm);
  }

  async getSuggestedTeam(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/getSuggestedTeam', teamVm);
  }

  async getAllMessages(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'messages/messages', userVm);
  }

  async getAllConversarionMessages(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'messages/conversation', userVm);
  }

  async sendChatMessage(messageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'messages/create', messageVm)
      .pipe(map(data => {
      }));
  }

  async sendNotification(notification: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'notifications/notify', notification)
      .pipe(map(data => {
      }));
  }

  async getAllNotifications(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'notifications/getAllNotifications', notificationVm);
  }

  async getAllIdeas(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/getAll', ideaVm);
  }

  async getAllFlaggedIdeas(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/getAllFlaggedIdeas', ideaVm);
  }

  async getAllChallengeIdeas(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/getAllIdeas', ideaVm);
  }

  async getSingleIdea(ideaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'idea/get', ideaVm);
  }

  async getNavbarNotifications(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'notifications/getNavbarNotifications', notificationVm)
      .pipe(map(async notifications => {
        this.setNotification(JSON.stringify(notifications));
      }, err => {
        this.setNotification('');
      }));
  }

  async markAllNotificationsAsRead(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'notifications/markAllNotificationsAsRead', notificationVm);
  }

  async issueNewVerificationEmail(verificationEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/issueNewVerificationEmail', verificationEmailVm);
  }

  async issueTownhallMulti(verificationEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/sendTownhallMulti', verificationEmailVm);
  }

  async issueTownhallOne(verificationEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/sendTownhallOne', verificationEmailVm);
  }

  async sendChallenge(verificationEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/sendChallenge', verificationEmailVm);
  }

  async issueManagerInvite(verificationEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/sendManagerInvite', verificationEmailVm);
  }

  async sendUserCompanyEmailInvitation(invitationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/sendUserCompanyEmailInvitation', invitationVm);
  }

  async emailVerification(verificationEmailVm: any) {
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/emailVerification', verificationEmailVm);
  }

  async markNotification(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'notifications/markNotification', notificationVm);
  }

  async forgotPassword(forgotPasswordVm: any) {
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/newPasswordRequestCreation', forgotPasswordVm);
  }

  async resetPassword(forgotPasswordVm: any) {
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/resetPassword', forgotPasswordVm);
  }

  async checkPasswordExpiration(forgotPasswordVm: any) {
    return await this.http.post<any>(this._configService.url + 'verificationGeneration/checkPasswordExpiration', forgotPasswordVm);
  }

  async logoutNotification(user: string) {
    return await this.http.put<any>(this._configService.url + 'user/logout/' + user, {})
      .pipe(map(data => {
      }));
  }

  async download(multimediaVm: any) {
    await this.checkAuthorization();
    return await this.http.post(this._configService.url + 'multimedia/download/', multimediaVm, { responseType: 'blob' });
  }

  async deleteFaq(faqVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'faq/deleteFaq', faqVm);
  }

  async deleteContactUsMessage(contactUsMessageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'newsletter/deleteContactUs', contactUsMessageVm);
  }

  async deleteUser(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/deleteUser', userVm);
  }

  async deleteUserAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/deleteUserAccount', userVm);
  }

  async resetProfilePicture(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/resetProfilePicture', userVm)
      .pipe(map(async user => {
        if (user && user.token) {
          await this.storage.setItem('token', user.token);
          await this.storage.setItem('id', user.user._id);
          delete user.token;
          await this.storage.setItem('currentUser', JSON.stringify(user.user));
          this.setUserLogin(JSON.stringify(user.user));
          user.user.isAuthorized = true;
          // this.setUser('');
        }
        return user;
      }));
  }

  async deleteAdminUserAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/deleteAdminUserAccount', userVm);
  }

  async deleteAdminTeam(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/deleteAdminTeam', teamVm);
  }

  async deleteChallenge(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/deleteChallenge', challengeVm);
  }

  async deleteInformationResource(coachVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'coach/deleteInformationResource', coachVm);
  }

  async deleteSurvey(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'survey/deleteSurvey', surveyVm);
  }

  async deleteProject(projectVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'project/deleteProject', projectVm);
  }

  async deleteMultimedia(multimediaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'multimedia/deleteMultimedia', multimediaVm);
  }

  async deleteAdminCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'company/deleteAdminCompany', companyVm);
  }

  async deleteAdminEvent(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'calendar/deleteAdminEvent', companyVm);
  }

  async deleteAdminCategory(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'category/deleteAdminCategory', categoryVm);
  }

  async enableAdminUserAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/enableAdminUserAccount', userVm);
  }

  async activateAdminTeam(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/activateAdminTeam', teamVm);
  }

  async activateChallenge(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'challenge/activateChallenge', challengeVm);
  }

  async publishSurvey(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'survey/publishSurvey', surveyVm);
  }

  async activateAdminCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'company/activateAdminCompany', companyVm);
  }

  async changeAdminUserRole(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/changeAdminUserRole', userVm);
  }

  async changeUserRole(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/changeUserRole', userVm);
  }

  async updateCoachPending(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/updatePendingCoach', userVm);
  }

  async changeUserCompany(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/changeUserCompany', userVm);
  }

  async systemInitialization(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'system/initialization', userVm);
  }

  async deleteConversation(messageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'messages/deleteconversation', messageVm);
  }

  async logout() {
    this.modalService.dismissAll();
    await this.setIsAuthorized(false);
    // await this.clearToken();
    await this.clearUser();
    let firebaseToken = await this.storage.getItem('firebaseToken');
    await this.storage.clear();
    await this.storage.setItem('firebaseToken', firebaseToken);
    await this.setUserLogin(JSON.stringify(''));
  }

  async updatePreonboard(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/updatePreonboard', userVm);
  }

  async updateOnboard(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/updateOnboard', userVm);
  }

  async updateValidated(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/updateValidation', userVm);
  }

  async getAllCMGs(teamVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'cmg/getAll', teamVm);
  }

  async createCmg(cmgVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'cmg/create', cmgVm)
      .pipe(map(data => {
      }));
  }

  async createMeetingSchedule(meetingVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'meeting-schedule/create', meetingVm)
      .pipe(map(data => {
      }));
  }

  async getCmgProfile(cmgVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'cmg/getCmgProfile', cmgVm);
  }

  async sendUserCMGInvitation(invitationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'cmgMails/sendUserCMGEmailInvitation', invitationVm);
  }

  async getAllIssues(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'participant-issue/getAll', userVm);
  }

  async getAllIssuesCoach(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'participant-issue/getAllCoach', userVm);
  }

  async getAllIssuesAdmin(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'participant-issue/getAllAdmin', userVm);
  }

  async getIssueProfile(issueVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'participant-issue/getIssueProfile', issueVm);
  }

  async getAllCmgMailsRecord(cmgMailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'cmgMails/getAll', cmgMailVm);
  }

  async createIssue(issueVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'participant-issue/createParIssue', issueVm)
      .pipe(map(data => {
      }));
  }
  async createTeams(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'team/createTeams', challengeVm);
  }

  async escalateIssue(issueVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'participant-issue/escalateIssue', issueVm)
      .pipe(map(data => {
      }));
  }

  async closeParIssue(issueVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'participant-issue/closeIssue', issueVm)
      .pipe(map(data => {
      }));
  }

  async addCMGMembers(cmgVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'cmg/addMembers', cmgVm);
  }

  async sendMessage(MessageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/sendMessage', MessageVm);
  }

  async sendReply(MessageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/sendReply', MessageVm);
  }

  async messageCoach(MessageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'participant-issue/coachMessage', MessageVm);
  }

  async messageParticipant(MessageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'participant-issue/participantMessage', MessageVm);
  }

  async getByName(UserVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/getUserByName', UserVm);
  }

  async isCustomer(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/isCustomer', userVm);
  }

  async notificationCreate(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'notifications/createGenericNotification', notificationVm);
  }

  async getNotifications(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'notifications/getAll', notificationVm);
  }

  async createLMSToken(lmsVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'lms-token/tokenUpload', lmsVm);
  }

  async getLMSTokens(lmsVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'lms-token/getAll', lmsVm);
  }

  async assignToken(lmsVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'lms-token/tokenAssign', lmsVm);
  }

  async courseComplete(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'user/updateUserCourseCompleted', userVm);
  }

  async assignOwnership(cmgVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'cmg/assignOwnership', cmgVm);
  }
  
  async getMyMailRecords(cmgMailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(this._configService.url + 'cmgMails/getMyMailRecords', cmgMailVm);
  }
}
