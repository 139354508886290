import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    moduleId: module.id,
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent implements OnInit {

    constructor(private location: Location, public router: Router) { }

    today: Date = new Date();

    async backClicked() {
        await this.location.back();
    }

    isMobile: boolean = false;
    
    ngOnInit(): void {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.isMobile = true;
          } else {
            this.isMobile = false;
          }
    }
}
