import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './main/footer/footer.module';
import { NavbarModule } from './main/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutingModule } from './app.routing';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TagInputModule } from 'ngx-chips';
import { ConfigService } from 'providers/config/config.service';
import { EventEmitterService } from 'providers/event-emitter/event-emitter.service';
import { AuthenticationService } from 'providers/authentication.service';
import { UserService } from 'providers/user.service';
import { StoreProvider } from 'providers/store/store';
import { RedirectGuard } from 'providers/redirect-guard';
import { NgbModule, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from './main/sidebar/sidebar.module';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { AvatarModule } from 'ngx-avatar';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DataSharingService } from 'providers/data-sharing-service/data-sharing-service';
import { IdeaViewComponent } from './idea-view/idea-view.component';
import { CoachingOverviewComponent, TimeSelectModalComponent } from './coaching-overview/coaching-overview.component';
import { CreateIdeasComponent } from './create-ideas/create-ideas.component';
import { CoachingDashManagerComponent } from './coaching-dash-manager/coaching-dash-manager.component';
import { TeamOverviewComponent, EditTeamLeader } from './team-overview/team-overview.component';
import { TownhallinviteComponent } from './townhallinvite/townhallinvite.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardService } from './onboard.service';
import { IssuesComponent } from './issues/issues.component';
import { ViewIssuesComponent } from './issues/view-issues/view-issues.component';
import { PaymentComponent } from './payment/payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ShowcaseComponent } from './showcase/showcase.component';
import { ApologiesComponent } from './apologies/apologies.component';
import { AdminMessagingComponent } from './admin-messaging/admin-messaging.component';
import { TeamMessagingComponent } from './team-messaging/team-messaging.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { MSAL_INSTANCE, MsalModule, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { ModalsComponent } from './modals/modals.component';
import { SpecialRecComponent } from './pages/app-area/special-rec/special-rec.component';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { EscalateModalComponent } from './modals/escalate-modal/escalate-modal.component';
import { CompanyAdminApproveProjectsComponent } from './pages/company-admin-area/company-admin-approve-projects/company-admin-approve-projects.component';
import { DisapproveProjectsComponent } from './pages/company-admin-area/company-admin-approve-projects/disapprove-projects/disapprove-projects.component';
import { TeamCharterComponent } from './team-charter/team-charter.component';
import { CharterSignedMembersComponent } from './team-charter/charter-signed-members/charter-signed-members.component';
import { TeamChatComponent } from './team-chat/team-chat.component';
import { AllProjectsComponent } from './all-projects/all-projects.component';

const cookieConfig: NgcCookieConsentConfig = {
    // Change to true when deploying in cloud. Cookie for specified domain i.e. amployees.ie. Change to new domain if needed.
    "enabled": true,
    "cookie": {
        // "domain": "localhost",
        "domain": "amployees.io",
    },
    "position": "bottom",
    "theme": "classic",
    "palette": {
        "popup": {
            "background": "#F3651F",
            "text": "#ffffff",
            "link": "#ffffff"
        },
        "button": {
            "background": "#ffffff",
            "text": "#F3651F",
            "border": "transparent"
        }
    },
    "type": "info",
    "content": {
        "message": "This website stores cookies on your computer. These cookies are used to collect information about how you use our website and to remember you. We use this information to improve and personalize your browsing experience, and for analysis and measurement data about our visitors, both on this website and through other media. See our Privacy Policy for more information about the cookies we use.",
        "dismiss": "Got it!",
        "deny": "Refuse cookies",
        "link": "Learn more",
        "href": "/#/privacy",
        "policy": "Cookie Policy"
    }
};

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: '0c0bbc5b-e454-4b17-80d5-4c49a20a1f02',
        redirectUri: 'http://localhost:4200',
        postLogoutRedirectUri: 'http://localhost:4200/login'
      }
    });
}

@NgModule({
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        IdeaViewComponent,
        CoachingOverviewComponent,
        CoachingDashManagerComponent,
        TeamOverviewComponent,
        TownhallinviteComponent,
        OnboardingComponent,
        // CreateIdeasComponent,
        EditTeamLeader,
        IssuesComponent,
        ViewIssuesComponent,
        PaymentComponent,
        ShowcaseComponent,
        ApologiesComponent,
        AdminMessagingComponent,
        TeamMessagingComponent,
        ModalsComponent,
        SpecialRecComponent,
        DeleteModalComponent,
        EscalateModalComponent,
        TimeSelectModalComponent,
        CompanyAdminApproveProjectsComponent,
        DisapproveProjectsComponent,
        TeamCharterComponent,
        CharterSignedMembersComponent,
        TeamChatComponent,
        AllProjectsComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        CommonModule,
        RouterModule,
        NgbModule,
        HttpModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedPluginModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserModule,
        HttpClientModule,
        InfiniteScrollModule,
        NgxDnDModule,
        // NgbDatepicker,
        AvatarModule,
        NgxChartsModule,
        // ComponentsModule,
        AppRoutingModule,
        NgxDatatableModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        NgMultiSelectDropDownModule.forRoot(),
        TagInputModule,
        NgxStripeModule.forRoot('pk_test_51NVrUZDn57tekA9JKRkw0NHf0IyxQFklmfpwcCr0IYBXD6k1m6j0M5DckM7wVhrI4hD79NRrb8neK0SY1KLxBiAx00hfzy0GUb'),
        MsalModule
    ],
    providers: [
        ConfigService,
        EventEmitterService,
        AuthenticationService,
        UserService,
        StoreProvider,
        DataSharingService,
        RedirectGuard,
        OnboardService,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
          },
        MsalService,
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
