import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  Injectable
} from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import Chart from 'chart.js';
import { NgbActiveModal, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CreateEventComponent } from 'app/pages/calendar-area/create-event/create-event.component';
import * as FileSaver from "file-saver";
import { FILE } from 'app/pages/challenge-area/view-challenge/view-challenge.component';
import { UploadFileComponent } from 'app/pages/multimedia-area/upload-file/upload-file.component';
import { CreateProjectComponent } from 'app/pages/project-area/create-project/create-project.component';
import * as Highcharts from 'highcharts';
import { OnboardService } from 'app/onboard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { teamCharterCoach } from './team-charter-coach-questions';


declare var $: any;

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

export interface USER {
  id: string;
  publicid: string;
  fullName: string;
  name: string;
  surname: string;
  email: string;
  company: string;
  industry: string;
  companyRole: string;
  avatar: string;
  enable: boolean;
}

export class TeamStatsVm {
  healthPersonal: number = 0;
  healthProfessional: number = 0;
  healthGroupScore: number = 0;
  objAndKeyResultsMetric1: number = 0;
  objAndKeyResultsMetric2: number = 0;
  objAndKeyResultsMetric3: number = 0;
  objAndKeyResultsMetric4: number = 0;
}

export class TEAM {
  id: string = '';
  name: string = '';
  description: string = '';
  company: string = '';
  category: string = '';
  challenge: string = '';
  id_challenge: string = '';
  id_project: string = '';
  membersTotal: number = 0;
  members: USER[] = [];
  active: boolean = false;
  teamStats?: TeamStatsVm = new TeamStatsVm();
}

export const teamsImgsArray: string[] = [
  'Rectangle_2783',
  'Rectangle_2785',
  'Rectangle_2788',
  'Rectangle_2786'
];

@Component({
  selector: 'app-team-overview',
  templateUrl: './team-overview.component.html',
  styleUrls: ['./team-overview.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class TeamOverviewComponent implements OnDestroy, OnInit, AfterViewInit {

  @ViewChild('teamOverview') teamOverview: ElementRef | undefined;
  @ViewChild('teamOverviewManager') teamOverviewManager: ElementRef | undefined;
  @ViewChild('teamOutputs') teamOutputs: ElementRef | undefined;

  closeResult = '';
  times: string[] = ['09:00 AM', '10:00AM', '11:00AM', '12:00 PM', '13:00PM', '14:00PM', '15:00PM', '16:00PM', '17:00PM', '18:00PM'];
  selectedDateTimes: { date: string; time: string }[] = [];

  teamform: FormGroup;

  fileName = "Browse Files";
  noFile: boolean = false;
  // loading: boolean = false;

  fileUpld(){
      this.noFile = true;
      this.loading = true;
  
    setTimeout(() => {
      this.loading = false;
      // this.noFile = true;
      this.fileName = "Showcase Presentation.ppt"
    }, 1500);
    
  }

  ngAfterViewInit(): void {
    if (this.role == 'User') {
      if (this.tourService.getTeamOverview() == false) {
        this.open(this.teamOverview);
        this.tourService.setTeamOverview(true);
      }
    } else if (this.role == 'Manager') {
      if (this.tourService.getTeamOverview() == false) {
        this.open(this.teamOverviewManager);
        this.tourService.setTeamOverview(true);
      }
    }
  }

  openOutputs() {
    if (this.role == 'Manager') {
      if (this.tourService.getTeamOutputs() == false) {
        this.open(this.teamOutputs);
        this.tourService.setTeamOutputs(true);
      }
    }
  }

  open(content: any) {

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );


  }

  private async getDismissReason(reason: any) {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  isUserManager: boolean = false;
  currentImageUrl: string = "https://fastly.picsum.photos/id/553/600/200.jpg?hmac=5arfu1DZR-AuFGHvDs3dKN5d6vmQz6RQIGy2UVyMdGw";

  user: string = '';
  token: string = '';
  company: string = '';
  id_team: string = '';
  role: string = '';;
  team: TEAM = new TEAM();
  userTeamId: string = '';
  challengeId: string = '';
  ideaId: string = '';
  userRequestSent: boolean = false;
  waitingForCoach: boolean = true;
  meetingLink: string;
  coach: string;
  teamLeader: string;
  isUserCoach: boolean = false;
  feedbackSuccess: boolean = false;
  feedbackAnswerSuccess: boolean = false;


  profileCompletition: number = 0;
  isUserAMember: boolean = false;
  loading: boolean = false;
  downloadError: boolean = false;
  isUserATeamLeader: boolean = false;

  showTeamsDetails: boolean = false;
  showTeamsProject: boolean = true;
  showTeamsCalendar: boolean = false;
  showTeamsCharter: boolean = false;
  progressBar: number = 90;

  timepicker = ["9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"]

  public usersRecords: USER[] = [];
  public files: FILE[] = [];

  private subscriptionUser: Subscription;

  public canvas: any;
  public ctx;

  teamCharterQuesAns = teamCharterCoach;

  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  scheduleform: FormGroup;
  submitted = false;
  success = false;
  errorQuantity = '';
  errorSize = '';
  error = '';
  returnUrl = '';
  hide = true;
  showError = false;
  theTitle;

  constructor(
    private _authService: AuthenticationService,
    private readonly _configService: ConfigService,
    private modalService: NgbModal,
    private router: Router,
    private tourService: OnboardService,
    private formBuilder: FormBuilder
  ) { }

  isMobile: boolean = false;
  public async ngOnInit() {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    // Register the validators
    this.scheduleform = this.formBuilder.group({
      option1: ['', Validators.required],
      option1Time: ['', Validators.required],

      option2: ['', Validators.required],
      option2Time: ['', Validators.required],

      option3: ['', Validators.required],
      option3Time: ['', Validators.required],
    });

    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.user = user['_id'];
      this.token = user['token'];
      this.company = user['company'];
      this.role = user['role'];
      this.userTeamId = user['teams'][0].team;
      this.isUserATeamLeader = user.hasOwnProperty('leaderOfTeam') ? true : false

      // if (user['stats'])
      //   this.profileCompletition = user['stats'].profileCompletition;

      // if (this.profileCompletition == 100 && user['team']) this.id_team = user['team']._id;
      // else this.router.navigate(['/dashboard']);

      if (user['role'] == 'Admin' || user['role'] == 'CompanyAdmin' || user['role'] == 'Manager') this.isUserManager = true;
      else this.isUserManager = false;
    });

    if (this.role == 'Coach') {
      this.isUserCoach = true;
    }

    if (this.role !== 'Admin') {
      if (!this.company) this.router.navigate(['/dashboard']);

      this.id_team = await this._authService.getTeam();
      if (!this.id_team) this.router.navigate(['/team-overview']);

      await this._authService.setProject('');
      await this.getTeamsProfile();
      await this.getUsersTeams();
      await this.getUserSchedules();
      await this.getFeedbackState();
      // await this.createEmployeeProfileFit();
    }

    this.setcharts();

    setTimeout(() => {
      this.charts(this.team.teamStats);
    }, 500);

    this.loadChart();
  }

  get f() { return this.scheduleform.controls; }

  SearchItems(event) {
    const val = event.target.value.toLowerCase();
    // this.teamsRecordsTemp.forEach(item => Object.keys(item).forEach(k => item[k] = (item[k] == null || item[k] == undefined) ? '' : item[k]));
    const temp = this.usersRecords.filter(function (d) {
      return d.name.toString().toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.team.members = temp;
  }

  openChangeImageModal() {
    // Trigger the modal using jQuery or Angular Material, or any other method you prefer
    // Here, we assume you have jQuery included
    $('#change-image-modal').modal('show');
  }

  saveImageChanges(selectedImageSrc: string) {
    // Update the current image URL with the selected image URL
    this.currentImageUrl = selectedImageSrc;

    // Store the selected image URL in localStorage
    localStorage.setItem('selectedImageUrl', selectedImageSrc);

    // Close the modal (assuming you are using Bootstrap modal)
    $('#change-image-modal').modal('hide');
  }

  openShowcaseModal() {
    $('#showcaseModal').modal('show');
  }

  uploadShowcase() {
    this.modalService.open(UploadcompleteComponent, { size: 'sm', backdrop: 'static', centered: true });
  }

  submitDates() {
    this.modalService.open(DateSubmitComponent, { size: 'md', backdrop: 'static', centered: true });
  }

  setcharts() {
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          //Get ctx from string
          var ctx = chart.chart.ctx;

          //Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || 'Arial';
          var txt = centerConfig.text;
          var color = centerConfig.color || '#000';
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
          //Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = (chart.innerRadius * 2);

          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight);

          //Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;

          //Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      }
    });
  }

  charts(stats: TeamStatsVm) {

    this.canvas = document.getElementById("healthPersonal");
    this.ctx = this.canvas.getContext("2d");

    var myChart = new Chart(this.ctx, {
      type: 'pie',
      data: {
        labels: [1, 2],
        datasets: [{
          label: "Personal",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ['#4acccd', '#f4f3ef'],
          borderWidth: 0,
          data: [stats.healthPersonal, 100 - stats.healthPersonal]
        }]
      },
      options: {
        elements: {
          center: {
            text: stats.healthPersonal + '%',
            color: '#66615c', // Default is #000000
            fontStyle: 'Arial', // Default is Arial
            sidePadding: 60 // Defualt is 20 (as a percentage)
          }
        },
        cutoutPercentage: 90,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: 'rgba(255,255,255,0.05)'
            }
          }],
          xAxes: [{
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(255,255,255,0.1)',
              zeroLineColor: "transparent"
            },
            ticks: {
              display: false,
            }
          }]
        },
      }
    });

    this.canvas = document.getElementById("healthProfessional");
    this.ctx = this.canvas.getContext("2d");

    var myChart = new Chart(this.ctx, {
      type: 'pie',
      data: {
        labels: [1, 2],
        datasets: [{
          label: "Professional",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ['#6bd098', '#f4f3ef'],
          borderWidth: 0,
          data: [stats.healthProfessional, 100 - stats.healthProfessional]
        }]
      },
      options: {
        elements: {
          center: {
            text: stats.healthProfessional + '%',
            color: '#66615c', // Default is #000000
            fontStyle: 'Arial', // Default is Arial
            sidePadding: 60 // Defualt is 20 (as a percentage)
          }
        },
        cutoutPercentage: 90,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: 'rgba(255,255,255,0.05)'
            }
          }],
          xAxes: [{
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(255,255,255,0.1)',
              zeroLineColor: "transparent"
            },
            ticks: {
              display: false,
            }
          }]
        },
      }
    });

    this.canvas = document.getElementById("healthGroupScore");
    this.ctx = this.canvas.getContext("2d");

    var myChart = new Chart(this.ctx, {
      type: 'pie',
      data: {
        labels: [1, 2],
        datasets: [{
          label: "Group Score",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ['#f17e5d', '#f4f3ef'],
          borderWidth: 0,
          data: [stats.healthGroupScore, 100 - stats.healthGroupScore]
        }]
      },
      options: {
        elements: {
          center: {
            text: stats.healthGroupScore + '%',
            color: '#66615c', // Default is #000000
            fontStyle: 'Arial', // Default is Arial
            sidePadding: 60 // Defualt is 20 (as a percentage)
          }
        },
        cutoutPercentage: 90,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "transparent",
              color: 'rgba(255,255,255,0.05)'
            }
          }],
          xAxes: [{
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(255,255,255,0.1)',
              zeroLineColor: "transparent"
            },
            ticks: {
              display: false,
            }
          }]
        },
      }
    });
  }

  async getTeamsProfile() {

    const userVm = {
      id: this.user,
      token: this.token,
      team: this.id_team == null ? this.userTeamId : this.id_team
    };
    await (await this._authService.getTeamProfile(userVm)).subscribe(async data => {

      this.files = [];

      const files = data.files;

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        this.files.push({
          id: file._id,
          name: file.media.name,
          newName: null,
          newDesc: null
        })
      }

      this.isUserAMember = data.isUserAMember;
      const team = data.team;
      this.usersRecords = [];
      for (let index = 0; index < team.members.length; index++) {
        const user = team.members[index];
        let usrFirstName = user['name'][0].toUpperCase() + user['name'].substr(1).toLowerCase();
        this.usersRecords.push({
          id: user._id,
          publicid: user.publicid,
          fullName: usrFirstName + ' ' + user['surname'],
          name: user['name'],
          surname: user['surname'],
          email: user['email'],
          company: user['company'],
          industry: user['industry'],
          companyRole: user['companyRole'],
          enable: user['enable'],
          avatar: this._configService.url + user['avatar']
        })
      }

      this.usersRecords.sort(function (a, b) {
        if (a.name > b.name) { return -1; }
        if (a.name < b.name) { return 1; }
        return 0;
      });

      this.team = {
        id: team._id,
        name: team.name,
        description: team.description,
        membersTotal: team.members.length,
        members: this.usersRecords,
        company: team.company.name,
        category: team.company.name,
        challenge: team.challenge.name,
        id_challenge: team.challenge._id,
        active: team.active,
        teamStats: team.stats,
        id_project: team.project
      }

      if (this.team.id_project)
        await this._authService.setProject(this.team.id_project);

      // this.usersRecordsTemp = this.usersRecords;

    }, err => {
      console.log('error');
    });
  }




  async createEmployeeProfileFit() {

    const userVm = {
      id: this.user,
      token: this.token,
      skillsMatch: 2,
      numberOfYearsInCurrentRole: 2,
      numberOfYearsInPreviousSimilarRole: 2,
      numberOfYearsInPreviousDifferentRoles: 2,
      departmentFunctionalArea: 2,
      learnNewSkills: 2,
      previousColloborativeExperience: 2,
      previousSoftwareToolsExperience: 2,
      employeeIdeaRanking: 2,
      ideaFit: 'null'
    };

    await (await this._authService.createEmployeeProfileFit(userVm)).subscribe(async data => {

      this.files = [];

      const files = data.files;

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        this.files.push({
          id: file._id,
          name: file.media.name,
          newName: null,
          newDesc: null
        })
      }

      this.isUserAMember = data.isUserAMember;
      const team = data.team;
      this.usersRecords = [];
      for (let index = 0; index < team.members.length; index++) {
        const user = team.members[index];
        this.usersRecords.push({
          id: user._id,
          publicid: user.publicid,
          fullName: user['name'] + ' ' + user['surname'],
          name: user['name'],
          surname: user['surname'],
          email: user['email'],
          company: user['company'],
          industry: user['industry'],
          companyRole: user['companyRole'],
          enable: user['enable'],
          avatar: this._configService.url + user['avatar']
        })
      }

      this.usersRecords.sort(function (a, b) {
        if (a.name > b.name) { return -1; }
        if (a.name < b.name) { return 1; }
        return 0;
      });

      this.team = {
        id: team._id,
        name: team.name,
        description: team.description,
        membersTotal: team.members.length,
        members: this.usersRecords,
        company: team.company.name,
        category: team.company.name,
        challenge: team.challenge.name,
        id_challenge: team.challenge._id,
        active: team.active,
        teamStats: team.stats,
        id_project: team.project
      }

      if (this.team.id_project)
        await this._authService.setProject(this.team.id_project);

      // this.usersRecordsTemp = this.usersRecords;

    }, err => {
      console.log('error');
    });
  }



  async moreInfo(user: USER) {
    // TBD
  }

  async downloadFile(file: FILE) {

    this.downloadError = false;

    const multimediaVm = {
      id: this.user,
      token: this.token,
      multimedia: file.id
    };

    await (await this._authService.download(multimediaVm)).subscribe((data) => {
      this.downloadError = false;
      FileSaver.saveAs(data, 'Team-' + this.team.name + '_' + file.name)

      this.Toast.fire({
        icon: 'success',
        title: 'File has been downloaded successfully!'
      })

    }, (error) => {
      this.downloadError = true;
      console.log('error', error);
    });
  }

  async createEvent() {

    if (!this.isUserManager && !this.isUserAMember) return;

    const modalRef = this.modalService.open(CreateEventComponent, { size: 'lg', backdrop: 'static', centered: true });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.token = this.token;
    // modalRef.componentInstance.challenge = null;
    modalRef.componentInstance.team = this.team.id;
    // modalRef.componentInstance.type = 'team';
    modalRef.result.then(async (result) => {
      if (result == 'success') {
        this.Toast.fire({
          icon: 'success',
          title: 'Your event has been created.'
        })
      }
      await this.getTeamsProfile();
    });
  }

  async createProject() {

    if (!this.isUserAMember && this.team.id_project) return;

    const modalRef = this.modalService.open(CreateProjectComponent, { size: 'lg', backdrop: 'static', centered: true });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.token = this.token;
    modalRef.componentInstance.team = this.team.id;
    modalRef.result.then(async (result) => {
      if (result == 'success') {
        this.Toast.fire({
          icon: 'success',
          title: 'Your project has been created.'
        })
      }
      await this.getTeamsProfile();
    });
  }

  async uploadFile() {

    if (!this.isUserManager && !this.isUserAMember) return;

    const modalRef = this.modalService.open(UploadFileComponent, { size: 'lg', backdrop: 'static', centered: true });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.token = this.token;
    modalRef.componentInstance.team = this.team.id;
    modalRef.result.then(async (result) => {
      if (result == 'success') {
        this.Toast.fire({
          icon: 'success',
          title: 'File(s) has(ve) been uploaded successfully.'
        })
      }
      await this.getTeamsProfile();
    });
  }

  async goToProject(project: string) {
    await this._authService.setProject(project);
    this.router.navigate(['/project']);
  }

  async enableUser(user: USER) {

    if (user.id == this.user) return;

    if (user.enable) {
      Swal.fire({
        title: 'Disable User Account. Are you sure?',
        icon: 'warning',
        html: '<div class="form-group">' +
          '<span data-notify="message"> This user\'s account will be disabled and the user will no longer have access to AMPloyees app.</span>' +
          '<br> <br>' +
          '<label>"' + user.fullName + '"</label>' +
          '<label>If so, enter your password</label>' +
          '<input id="input-field" type="password" class="form-control"/>' +
          '</div>',
        showCancelButton: true,
        customClass: {
          cancelButton: 'btn btn-danger',
          confirmButton: 'btn btn-success',
        },
        confirmButtonText: 'Yes, disable it!',
        buttonsStyling: false,
        preConfirm: () => {
          const password = $('#input-field').val();
          if (!password)
            Swal.showValidationMessage(
              'Password is required'
            )
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(async (result: any) => {
        const password = $('#input-field').val();
        if (result.value && password) {
          let userVm = {
            id: this.user,
            token: this.token,
            user: user.id,
            password: password
          };
          await (await this._authService.enableAdminUserAccount(userVm)).subscribe(async data => {
            Swal.fire({
              title: 'Disabled!',
              text: 'User\'s account has been disabled and the user will be logged out from AMPloyees application.',
              icon: 'success',
              customClass: {
                confirmButton: "btn btn-success",
              },
              buttonsStyling: false
            })
            await this.getTeamsProfile();
          }, err => {
            console.log('err', err);
            let error = 'An error has occurred.'
            if (err.error.message) error = err.error.message;
            Swal.fire({
              title: 'Failed!',
              text: error + ' User\'s account has not been disabled. Please Try Again.',
              icon: 'error',
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false
            })
          })
        } else {
          Swal.fire({
            title: 'Cancelled',
            text: 'User\'s account has not been disabled.',
            icon: 'error',
            customClass: {
              confirmButton: "btn btn-info",
            },
            buttonsStyling: false
          })
        }
      })
    } else {
      Swal.fire({
        title: 'Enable User Account. Are you sure?',
        icon: 'warning',
        html: '<div class="form-group">' +
          '<span data-notify="message"> This user\'s account will be enabled and the user will have access to AMPloyees app.</span>' +
          '<br> <br>' +
          '<label>"' + user.fullName + '"</label>' +
          '<label>If so, enter your password</label>' +
          '<input id="input-field" type="password" class="form-control"/>' +
          '</div>',
        showCancelButton: true,
        customClass: {
          cancelButton: 'btn btn-danger',
          confirmButton: 'btn btn-success',
        },
        confirmButtonText: 'Yes, enable it!',
        buttonsStyling: false,
        preConfirm: () => {
          const password = $('#input-field').val();
          if (!password)
            Swal.showValidationMessage(
              'Password is required'
            )
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(async (result: any) => {
        const password = $('#input-field').val();
        if (result.value && password) {
          let userVm = {
            id: this.user,
            token: this.token,
            user: user.id,
            password: password
          };
          await (await this._authService.enableAdminUserAccount(userVm)).subscribe(async data => {
            Swal.fire({
              title: 'Enabled!',
              text: 'User\'s account has been enabled and the user will be logged out from AMPloyees application.',
              icon: 'success',
              customClass: {
                confirmButton: "btn btn-success",
              },
              buttonsStyling: false
            })
            await this.getTeamsProfile();
          }, err => {
            console.log('err', err);
            let error = 'An error has occurred.'
            if (err.error.message) error = err.error.message;
            Swal.fire({
              title: 'Failed!',
              text: error + ' User\'s account has not been enabled. Please Try Again.',
              icon: 'error',
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false
            })
          })
        } else {
          Swal.fire({
            title: 'Cancelled',
            text: 'User\'s account has not been enabled.',
            icon: 'error',
            customClass: {
              confirmButton: "btn btn-info",
            },
            buttonsStyling: false
          })
        }
      })
    }
  }

  async deleteUser(user: USER) {

    if (user.id == this.user) return;

    Swal.fire({
      title: 'Delete User Account. Are you sure?',
      icon: 'warning',
      html: '<div class="form-group">' +
        '<span data-notify="message"> This user\'s account will be deleted and the user will no longer have access to AMPloyees app. All the data will be deleted and the account cannot be restored.</span>' +
        '<br> <br>' +
        '<label>"' + user.fullName + '"</label>' +
        '<label>If so, enter your password</label>' +
        '<input id="input-field" type="password" class="form-control"/>' +
        '</div>',
      showCancelButton: true,
      customClass: {
        cancelButton: 'btn btn-danger',
        confirmButton: 'btn btn-success',
      },
      confirmButtonText: 'Yes, delete it!',
      buttonsStyling: false,
      preConfirm: () => {
        const password = $('#input-field').val();
        if (!password)
          Swal.showValidationMessage(
            'Password is required'
          )
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result: any) => {
      const password = $('#input-field').val();
      if (result.value && password) {
        let userVm = {
          id: this.user,
          token: this.token,
          user: user.id,
          password: password
        };
        await (await this._authService.deleteAdminUserAccount(userVm)).subscribe(async data => {
          Swal.fire({
            title: 'Deleted!',
            text: 'User\'s account has been deleted and the user will be logged out from AMPloyees application.',
            icon: 'success',
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false
          })
          await this.getTeamsProfile();
        }, err => {
          console.log('err', err);
          let error = 'An error has occurred.'
          if (err.error.message) error = err.error.message;
          Swal.fire({
            title: 'Failed!',
            text: error + ' User\'s account has not been deleted. Please Try Again.',
            icon: 'error',
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false
          })
        })
      } else {
        Swal.fire({
          title: 'Cancelled',
          text: 'User\'s account has not been deleted.',
          icon: 'error',
          customClass: {
            confirmButton: "btn btn-info",
          },
          buttonsStyling: false
        })
      }
    })
  }

  ngOnDestroy() {
    this.subscriptionUser.unsubscribe();
  }

  async joinTeam() {
    if (this.isUserAMember) return

    const userVm = {
      id: this.user,
      token: this.token,
      team: this.id_team
    };

    this.loading = true;
    await (await this._authService.joinTeam(userVm))
      .pipe(first()).subscribe(async data => {
        await this.getTeamsProfile();
        this.loading = false;
        this.Toast.fire({
          icon: 'success',
          title: 'You have joined ' + this.team.name + ' team successfully.'
        })
      }, err => {
        this.Toast.fire({
          icon: 'error',
          title: 'An error has occurred: ' + err.error.message + ' Please try again.'
        })
        this.loading = false;
      })

  }

  async leaveTeam() {
    if (!this.isUserAMember) return

    const userVm = {
      id: this.user,
      token: this.token,
      team: this.id_team
    };

    this.loading = true;
    await (await this._authService.leaveTeam(userVm))
      .pipe(first()).subscribe(async data => {
        // await this.getTeamProfile();
        await this._authService.setChallenge(this.team.id_challenge);
        this.router.navigate(['/challenge-view']);
        this.loading = false;
        this.Toast.fire({
          icon: 'success',
          title: 'You have left ' + this.team.name + ' team successfully.'
        })
      }, err => {
        this.Toast.fire({
          icon: 'error',
          title: 'An error has occurred: ' + err.error.message + ' Please try again.'
        })
        this.loading = false;
      })
  }

  public options: any = {};

  loadChart() {
    this.options = {
      legend: {
        enabled: false
      },
      chart: {
        type: 'line'
      },
      yAxis: {
        tickInterval: 3,
        // min: 4
        title: {
          text: '',
        },
        gridLineDashStyle: 'ShortDot',
        // gridLineWidth: 3,
        gridLineColor: '#000000'
      },
      xAxis: {
        categories: ['22/09', '23/09', '24/09', '25/09', '26/09', '27/09', '28/09', '29/09', '30/09']
      },
      title: {
        text: '',
      },
      series: [{
        name: '',
        data: [3, 2, 5, 4, 3, 7, 7, 8, 9],
        color: '#F3651F',
        lineWidth: 1
      }]

    }
    Highcharts.chart('container', this.options);
  }

  allTeamsRecords = [];
  teamsRecords = [];
  allTeamsNames = [];
  userTeams;
  meetingSchedules;

  async getManagerTeams() {

    const teamVm = {
      id: this.user,
      token: this.token,
    };

    await (await this._authService.getManagerAllTeams(teamVm)).subscribe(newData => {
      this.allTeamsRecords = [];
      this.teamsRecords = [];
      this.allTeamsRecords = newData;

      this.allTeamsRecords.forEach(element => {
        let teamId = element['id'];
        let teamName = element['name'];
        let image = 'assets/img/teams/team-img/' + teamsImgsArray[Math.floor(Math.random() * teamsImgsArray.length)] + '.png'
        let team = { "teamName": teamName, "image": image, "teamId": teamId }
        this.teamsRecords.push(team);
        this.allTeamsNames.push(teamName);
      });

      return this.teamsRecords;
    }, err => {
      console.log('error');
    });
  }

  async editTeam(team: TEAM) {
    const modalRef = this.modalService.open(EditTeamLeader, { size: 'lg', backdrop: 'static', centered: true });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.token = this.token;
    modalRef.componentInstance.team = team;
    modalRef.result.then(async (result) => {
      if (result == 'success') {
        this.Toast.fire({
          icon: 'success',
          title: 'The team has been edited.'
        })
      }
      await this.getManagerTeams();
      this.ngOnInit();
      setTimeout(() => {
        for (let index = 0; index < this.teamsRecords.length; index++) {
          const stats = this.teamsRecords[index].teamStats;
          this.charts(stats);
        }
      }, 100);

    });
  }

  async getUsersTeams() {
    this.userTeams = {};

    const teamVm = {
      id: this.user,
      token: this.token,
    };

    await (await this._authService.getUserTeams(teamVm)).subscribe(newData => {
      const teamsData = newData;
      teamsData.forEach(team => {
        this.userTeamId = team["_id"];
        this.challengeId = team.challenge["_id"];
        this.ideaId = team.idea["_id"];
        let name = team["name"];
        let description = team["description"];
        let active = team["active"];
        let coach = team["coach"];
        let ideaTitle = team.idea["title"];
        let ideaDescription = team.idea["text"];
        let deadline = moment(team.challenge["deadline"]).format('DD/MM/YY');
        let teamLeader = team.teamLeader;
        let teamData = { "teamName": name, "teamDes": description, "active": active, "ideaName": ideaTitle, "ideaDes": ideaDescription, "members": team.members, "deadline": deadline }
        this.userTeams = teamData;
        this.teamLeader = teamLeader['name'] + " " + teamLeader['surname']
      });

      return this.userTeams;
    }, err => {
      console.log('error');
    });
  }

  async onSubmit() {
    this.submitted = true;
    this.showError = false;
    this.success = false;

    // If there was an error in validation
    if (this.scheduleform.invalid) return;

    // Disable the button and show the spinner
    this.loading = true;

    const meetingVm = {
      id: this.user,
      token: this.token,
      creator: this.user,
      team: this.userTeamId,
      challenge: this.challengeId,
      idea: this.ideaId,
      proposedSlots: [
        [
          this.f.option1.value,
          this.f.option1Time.value
        ],
        [
          this.f.option2.value,
          this.f.option2Time.value
        ],
        [
          this.f.option3.value,
          this.f.option3Time.value
        ]
      ],
      "coach": null,
      "meetingLink": ""
    };

    await (await this._authService.createMeetingSchedule(meetingVm))
      .pipe(first()).subscribe(data => {
        this.loading = false;
        this.submitted = false;
        this.success = true;

        this.Toast.fire({
          icon: 'success',
          title: 'Proposed Slots sent Successfully !!'
        })
        this.getUserSchedules();
      }, err => {
        this.showError = true;
        this.error = err || 'There was an error';
        this.loading = false;
        this.submitted = false;
      })
    await this.router.navigate(['/team-overview']);
  }

  async getUserSchedules() {
    this.userTeams = {};
    this.meetingSchedules = {};
    let teamCoachId;

    const scheduleVm = {
      id: this.user,
      token: this.token,
      team: this.userTeamId
    };

    await (await this._authService.getMySchedules(scheduleVm)).subscribe(newData => {
      const scheduleData = newData;
      this.userRequestSent = scheduleData.length > 0 ? true : false;

      // Getting weekday
      var dayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      scheduleData.forEach(schedule => {
        let creator = schedule.creator;
        teamCoachId = schedule.coach._id;
        let team = schedule.team;
        let challenge = schedule.challenge;
        let idea = schedule.idea;
        let proposedSlots = schedule.proposedSlots;
        let acceptedSlots = schedule.acceptedSlots;
        this.waitingForCoach = schedule.waitingForAcceptance;
        this.meetingLink = schedule.meetingLink;
        //coverting to date
        // let dateParts = schedule.acceptedSlots[0].split("-");

        // let dateObject = new Date(+dateParts[2], dateParts[1] - 1, + dateParts[0]);
        // let dateObject = new Date(dateParts[2], dateParts[1] - 1, + dateParts[0]);
        // let dayName = dayNames[dateObject.getDay()];

        // formatting date to dd/mm/yyyy
        let newDate = '';
        let date = new Date(schedule.acceptedSlots[0]);
        if (!isNaN(date.getTime())) {
          newDate = + date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        }
        let dayName = dayNames[date.getDay()]

        let meetData = { "date": newDate, "time": acceptedSlots[1], "weekday": dayName }
        this.meetingSchedules = meetData;

        this.coach = schedule.coach["name"] + " " + schedule.coach["surname"];
      });


      return this.meetingSchedules;
    }, err => {
      console.log('error');
    });
  }

  redirect() {
    let url = this.meetingLink;
    window.open(url, '_blank');
  }

  async goToCharter() {
    await this.router.navigate(['/team-charter']);
  }

  async sendFeedback() {

    setTimeout(() => {
      this.router.navigate(['/dashboard']);
      // }, 5000);
    });


    this.Toast.fire({
      icon: 'success',
      title: 'Your feedback has been sent successfully !'
    })

    this.feedbackSuccess = true;
    localStorage.setItem('feedbackSuccess', "true");

  }

  async getFeedbackState() {
    this.feedbackSuccess = localStorage.getItem('feedbackSuccess') == "true" ? true : false;
    this.feedbackAnswerSuccess = localStorage.getItem('feedbackAnswerSuccess') == "true" ? true : false;
    // localStorage.setItem('feedbackSuccess', "false");
    // localStorage.setItem('feedbackAnswerSuccess', "false");

    console.log('this.feedbackSuccess :>> ', this.feedbackSuccess);
    console.log('this.feedbackAnswerSuccess :>> ', this.feedbackAnswerSuccess);
  }

  async setFeedbackAnswer(){
    localStorage.setItem('feedbackAnswerSuccess', "true");
    this.Toast.fire({
      icon: 'success',
      title: 'Your request has been sent successfully !'
    })
  }
}

@Component({
  selector: 'app-team-leader-edit-team',
  templateUrl: './team-overview-edit-team.component.html',
})
export class EditTeamLeader implements OnInit {

  @Input() user: string;
  @Input() token: string;
  @Input() team: TEAM;

  success = false;
  loading = false;
  submitted = false;
  showError = false;
  namePresent = false;

  teamform: FormGroup;

  constructor(public activeModal: NgbActiveModal,
    private _authService: AuthenticationService,
    private formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.teamform = this.formBuilder.group({
      name: [this.team.name, [Validators.required]],
      description: [this.team.description, [Validators.required]]
    });
  }

  get f() { return this.teamform.controls; }

  async onSubmit() {

    this.submitted = true;
    this.showError = false;
    this.namePresent = false;

    if (this.teamform.invalid) return;

    const teamVm = {
      id: this.user,
      token: this.token,
      team: this.team.id,
      name: this.f.name.value,
      description: this.f.description.value,
    };

    this.loading = true;
    await (await this._authService.editTeam(teamVm))
      .pipe(first()).subscribe(data => {
        this.loading = false;
        this.submitted = false;
        this.success = true;
        setTimeout(() => {
          this.activeModal.close('success')
        }, 3000);
      }, err => {
        if (err.error.name == "BadRequestException") {
          this.namePresent = true;
        } else {
          this.showError = true;
        }
        this.loading = false;
        this.submitted = false;
      })
  }
}

@Component({
  selector: 'app-team-overview',
  templateUrl: './upload-complete.html',
  styleUrls: ['./team-overview.component.css']
})

export class UploadcompleteComponent {

  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
    private readonly _configService: ConfigService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  // confirmDelete(){
  //   this.modalService.open(DeleteConfirmComponent, { size: 'md', backdrop: 'static', centered: true })
  // }

  closeModal() {
    this.activeModal.close();
  }

  undoUpload() {

  }

  onCloseClick() {
    this.activeModal.close();
  }
}

@Component({
  selector: 'app-team-overview',
  templateUrl: './date-submit.html',
  styleUrls: ['./team-overview.component.css']
})

export class DateSubmitComponent {

  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
    private readonly _configService: ConfigService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  // confirmDelete(){
  //   this.modalService.open(DeleteConfirmComponent, { size: 'md', backdrop: 'static', centered: true })
  // }

  closeModal() {
    this.activeModal.close();
  }

  undoUpload() {

  }

  onCloseClick() {
    this.activeModal.close();
  }
}

