import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { StoreProvider } from "./store/store";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
    private storage: StoreProvider,
        private _authService: AuthenticationService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let user = await this.storage.getItem('currentUser');
        if (user) {
            this._authService.setIsAuthorized(true);
            this._authService.setUserLogin(user);
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
