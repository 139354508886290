import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { StoreProvider } from 'providers/store/store';
import { ModalsComponent } from '../modals.component';

@Component({
  selector: 'app-escalate-modal',
  templateUrl: './escalate-modal.component.html',
  styleUrls: ['./escalate-modal.component.css']
})
export class EscalateModalComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private storage: StoreProvider,
        private router: Router,
        private modalService: NgbModal,
        private readonly _configService: ConfigService,
        public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
  }

  confirmEscalate(){
    this.activeModal.close();
    this.modalService.open(EscalateModalConfirmComponent, { size: 'md', backdrop: 'static', centered: true })
  }

  onCloseClick() {
    this.activeModal.close();
  }

  cancelEscalate() {
    this.activeModal.close();
    this.modalService.open(ModalsComponent, { size: 'lg', backdrop: 'static', centered: true })
  }

}

@Component({
  selector: 'app-escalate-modal',
  templateUrl: './escalate-confirm.html',
  styleUrls: ['./escalate-modal.component.css']
})
export class EscalateModalConfirmComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private storage: StoreProvider,
        private router: Router,
        private modalService: NgbModal,
        private readonly _configService: ConfigService,
        public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
  }

  undoEscalate(){
  }

  onCloseClick() {
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

}
