export const teamCharter =
    [
        {
            "name": "teamCharter1q1",
            "question": "What was the best team that you were a member of? This could be work related or voluntary or sports. What was it about this team that made the experience so memorable and fulfilling for you?",
            "description": "At the outset you are encouraged to draw from your own experience to design and create ‘your perfect team.’ Each team member needs to reflect and share their experience. Any common themes/principles should be captured here. The other sections of the Team Charter need to be consistent with these guiding themes/principles."
        },
        {
            "name": "teamCharter1q2",
            "question": "What are your team values?",
            "description": "At the outset you are encouraged to draw from your own experience to design and create ‘your perfect team.’ Each team member needs to reflect and share their experience. Any common themes/principles should be captured here. The other sections of the Team Charter need to be consistent with these guiding themes/principles."
        },
        {
            "name": "teamCharter1q3",
            "question": "What has motivated each of you to get involved in this project? Share your individual motivations. Can we embrace our personal goals into a Common Sense of Purpose?",
            "description": "Sense of Purpose defines what gives the team meaning. It is the reason why the team exists in the first place. Meaning and purpose are what drives people."
        },
        {
            "name": "teamCharter1q4",
            "question": "Is there a team logo and/or motto which reflects your Common Sense of Purpose",
            "description": "Team name, logo or motto can help remind the team of their Common Sense of Purpose."
        },
        {
            "name": "teamCharter1q5",
            "question": "What are the skills and experiences that exist within your team?",
            "description": "Understanding the full range of skills and experience from the outset of the project to ensure that the team utilizes their full potential."
        },
        {
            "name": "teamCharter1q6",
            "question": "In this section the team references the relevant Code of Conduct or Ethics and any relevant HR policies and procedures or those related to Project Governance relevant to remote and hybrid team working. These are confidential and are specific to the organization itself.",
            "description": "The sign off of the Team Charter acknowledges that team members are aware of the obligations and agree to comply fully with the various organizational codes, policies, and procedures."
        },
        {
            "name": "teamCharter1q7",
            "question": "In this section we provide teams with recommended best practices for remote and hybrid working teams. This is based on an accumulation of Mehhal experience in coaching teams while drawing from the latest research in this area. We also provide teams with a Guide to Best Practices which is available as a resource on our platform. The best practices reflect the conditions recommended by Mehhal for creating and maintaining effective teams.",
            "description": "Please feel free to add/amend/delete best practices to reflect the team members’ own unique and personal experience of working in successful teams."
        },
    ]