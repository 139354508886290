import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

// public url = 'https://amployees.io/api/'; //Digital Ocean Deployment
//  public url = 'http://localhost:8080/'; //Local Deployment
  // public url = 'http://192.168.1.31:8080/'; //Franklin's W10 Deployment 
  // public url = 'http://192.168.201.33:8080/'; //Franklin's MAC/SRI Deployment 
  public url = 'https://api.humanitylab.io/'; //Franklin's MAC/SRI Deployment 
}
