import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'providers/authentication.service';

export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid ? null : {
    isValid: {
      valid: false
    }
  };
};

export const MAXFILES = 100;
declare var $: any;

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  @Input() user: string;
  @Input() token: string;
  @Input() team: string;
  @Input() challenge: string;
  @Input() project: string;

  success = false;
  loading = false;
  submitted = false;
  showError = false;

  errorQuantity = '';
  errorSize = '';

  uploadform: FormGroup;
  fileToUpload: Array<File> = [];

  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(public activeModal: NgbActiveModal,
    private _authService: AuthenticationService,
    private formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.uploadform = this.formBuilder.group({
      checked: [false, Validators.requiredTrue],
    });
  }

  fileChangeEvent(fileInput: any) {
    if (!fileInput) this.fileToUpload = [];
    else if (fileInput.target.files && fileInput.target.files.length < 100)
      for (let index = 0; index < fileInput.target.files.length; index++)
        this.fileToUpload.push(<File>fileInput.target.files[index]);
    this.checkFile();
  }

  removeItem(index: number) {
    this.fileToUpload.splice(index, 1);
    this.checkFile();
  }

  checkFile() {
    if (this.fileToUpload.length > MAXFILES) this.errorQuantity = 'Too many files';
    else this.errorQuantity = '';

    const isTooBig = this.fileToUpload.some(file => file.size > 500 * 1e+6);

    if (!isTooBig) this.errorSize = ''
    else this.errorSize = 'File exceeds the maximum upload size.'

    if (this.fileToUpload.length == 0) $(".fileinput").fileinput("clear");
  }

  get f() { return this.uploadform.controls; }


  async onSubmit() {

    this.submitted = true;
    this.showError = false;
    this.success = false;

    // If there was an error in validation
    if ((this.fileToUpload && this.fileToUpload.length == 0) || this.uploadform.invalid) return;

    // Disable the button and show the spinner
    this.loading = true;


    const multimediaVm = {
      id: this.user,
      token: this.token,
      team: this.team ? this.team : null,
      challenge: this.challenge ? this.challenge : null,
      project: this.project ? this.project : null,
    }

    let formData: any = new FormData();
    for (let index = 0; index < this.fileToUpload.length; index++)
      formData.append('file', this.fileToUpload[index]);

    Object.keys(multimediaVm).forEach(key => formData.append(key, multimediaVm[key]));

    await (await this._authService.uploadMultimedia(formData))
      .pipe(first()).subscribe(data => {
        this.loading = false;
        this.submitted = false;
        this.success = true;

        setTimeout(() => {
          this.success = false;
        }, 15000);

        setTimeout(() => {
          this.activeModal.close('success')
        }, 3000);

      }, err => {
        this.showError = true;
        let error = 'An error has occurred.'
        if (err.error.message) error = err.error.message;
        this.Toast.fire({
          icon: 'error',
          title: ' Your file(s) has(ve) not been uploaded. ' + error + ' Please Try Again.',
        })
        this.loading = false;
        this.submitted = false;
      })
  }
}

