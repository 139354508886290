import { Component, OnInit, ViewChild } from '@angular/core';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
// import { DataService } from 'app/data.service';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { ConfigService } from 'providers/config/config.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'providers/authentication.service';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  private subscriptionUser: Subscription;

  user: string = '';
  token: string = '';
  preonboarded: Boolean = false;
  theUser: any;

  payStatus = false;
  tier = null;
  bundle = [];
  howManyFields = [];

  coach: string = '';
  participant1: string = '';
  participant2: string = '';
  participant3: string = '';
  participant4: string = '';
  participant5: string = '';

  constructor(
    private stripeService: StripeService,
    // private service: DataService,
    private http: HttpClient,
    private config: ConfigService,
    private _authService: AuthenticationService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      const user: any[] = value ? JSON.parse(value) : {};
      this.theUser = user;
      console.log(user)
      console.log(this.theUser)
      this.payStatus = this.theUser.paymentStatus;
      this.tier = this.theUser.planType;
      this.bundle = this.theUser.bundledUsers;
      
     
      this.user = user['_id'];
      this.token = user['token'];
    });

    this.http.post(this.config.url+'user/updatePayment', {id: this.user, token: this.token, user: this.theUser.email}).subscribe(res => {
      console.log(res)
    })
  }

  async getBundle(){

    let userVm = {
      id: this.user,
      token: this.token,
      publicid: this.theUser.publicid
    }

    await (await this._authService.getUserProfile(userVm)).subscribe(response => {
      console.log(response)
      this.bundle = response.bundledUsers;      
    })
  }

  checkout(priceId) {
    // Check the server.js tab to see an example implementation
    this.http.post(this.config.url+'user/create-checkout-session', { id: this.user, token: this.token, user: this.theUser, priceId: priceId })
      .pipe(
        switchMap(session => {
          console.log(session)
          // return this.stripeService.redirectToCheckout({ sessionId: session.id })
          return null;
        })
      )
      .subscribe(result => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        // if (result.error) {
        //   alert(result.error.message);
        // }
      });
  }

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  }; elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  // customerID;

  // onClickSubmit(data) {
  //   this.service.createCustomer({ email: data.emailID }).subscribe((res) => {
  //     // this.customerID = res.customer.id;
  //     console.log(this.customerID);
  //   });
  // }

  // PaymentMethodID;

  // createToken(): void {
  //   this.stripeService.createPaymentMethod({
  //     type: 'card',
  //     card: this.card.element,
  //     billing_details: { name: null },
  //   }).subscribe((result) => {
  //     if (result.paymentMethod) {
  //       const pack = {
  //         paymentMethodId: result.paymentMethod,
  //         customerID: this.customerID,
  //       };// Send the payment method and customer ID to your server
  //       this.service.StartSubscription(pack).subscribe((res) => {
  //         console.log(res);
  //       });
  //       console.log(result.paymentMethod.id);
  //     } else if (result.error) {
  //       // Error creating the token
  //       console.log(result.error.message);
  //     }
  //   });
  // }

  closeResult = '';


  open(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  async submit(){

    let same: boolean = false;
    let empty: boolean = false;


    let coach = {"user": this.coach, "isCoach": true};
    let participant1 = {"user": this.participant1, "isCoach": false}
    let participant2 = {"user": this.participant2, "isCoach": false}
    let participant3 = {"user": this.participant3, "isCoach": false}
    let participant4 = {"user": this.participant4, "isCoach": false}
    let participant5 = {"user": this.participant5, "isCoach": false}


    let users = [];
    if(this.coach != '')
      users.push(coach);
    if(this.participant1 != '')
      users.push(participant1);
    if(this.participant2 != '')
      users.push(participant2);
    if(this.participant3 != '')
      users.push(participant3);
    if(this.participant4 != '')
      users.push(participant4);
    if(this.participant5 != '')
      users.push(participant5);


      console.log(same)
      console.log(users.length)

      if(users.length == 0){
        empty = true;
      }

      const status = users.some(user => {
        let counter  = 0;
        for (const iterator of users) {
          if (iterator.user === user.user) {
            counter += 1;
          }
        }        
        if(counter > 1){
          console.log("dupe!")
          same = true;
        }
        return counter > 1;
      }

      );

      console.log(this.bundle)

      let theIds = [];
      let theRes = [];
      let filteredRes = [];

      let match: boolean = false;

    this.http.post(this.config.url + 'user/getUsersByEmail', { id: this.user, token: this.token, email: users })
      .subscribe(result => {
        theRes = Object.keys(result).map(key => ({type: key, value: result[key]}));
        console.log(theRes)

        for(let i = 0; i<= theRes.length - 1; i++){
          filteredRes.push(theRes[i].value)
        }

        for(let i = 0; i<= filteredRes.length - 1; i++){
          theIds.push(filteredRes[i]._id)
        }

        // for(let i = 0; i<= theIds.length - 1; i++){
        //   for(let j = 0; j<= this.bundle.length - 1; i++){
        //     if(theIds[i] == this.bundle[j]){
        //       console.log("match in ids!")
        //       match = true;
        //     }
        //   }
        // }

        const intersection = theIds.filter(element => this.bundle.includes(element));

        console.log(theIds)
        console.log(intersection)
        if(intersection.length > 0){
          console.log("match")
          match = true;
        }
        // for(let i = 0; i<= result.length - 1; i++){

        // }
        if(same == false && empty == false && match == false){
    
          console.log("sending!")
          for(let i = 0; i<= theIds.length - 1; i++){
            this.bundle.push(theIds[i])
          }

          console.log(this.bundle)
    
          this.http.post(this.config.url+'user/addBundledUser', { id: this.user, token: this.token, users: users })
            .subscribe(result => {
            });
            
          }   
        });     
        
    console.log(users);


  }
}





