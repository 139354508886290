import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { AvatarModule } from 'ngx-avatar';
import { DateAgoPipe } from 'app/pipes/date-ago.pipe';

@NgModule({
    imports: [RouterModule, CommonModule, AvatarModule],
    declarations: [NavbarComponent, DateAgoPipe],
    exports: [NavbarComponent]
})

export class NavbarModule { }
