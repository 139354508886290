import { first } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import Chart from 'chart.js';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateEventComponent } from 'app/pages/calendar-area/create-event/create-event.component';
import * as FileSaver from "file-saver";
import { FILE } from 'app/pages/challenge-area/view-challenge/view-challenge.component';
import { UploadFileComponent } from 'app/pages/multimedia-area/upload-file/upload-file.component';
import { CreateProjectComponent } from 'app/pages/project-area/create-project/create-project.component';
import * as Highcharts from 'highcharts';
import { OnboardService } from 'app/onboard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export class ISSUE {
  id:string; 
  team:string; 
  coach:string; 
  issueTitle:string; 
  description:string; 
  outlineImpact:string; 
  addressIssueSteps:string; 
  describeNeeded:string; 
  solved: boolean 
}

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.css']
})
export class IssuesComponent implements OnInit {

  public issueData = [];

  private subscriptionUser: Subscription;

  isUserManager: boolean = false;
  user: string = '';
  token: string = '';
  company: string = '';
  issueId: string = '';
  loading: boolean = false;


  constructor(private _authService: AuthenticationService,
    private readonly _configService: ConfigService,
    private modalService: NgbModal,
    private router: Router,
    private tourService: OnboardService) { }

  isMobile: boolean = false;
  theUser;
  onboarded: Boolean = false;
  role;
  preonboarded: Boolean = false;

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.user = user['_id'];
      this.token = user['token'];
      this.company = user['company'];
      this.role = user['role'];

      if (user['role'] == 'Admin' || user['role'] == 'CompanyAdmin' || user['role'] == 'Manager') this.isUserManager = true;
      else this.isUserManager = false;

    });

    this.getAllIssues();
    if(this.role == 'Coach'){
      this.getAllIssuesCoach();
    }

    if(this.role == 'Admin'){
      this.getAllIssuesAdmin();
    }
  }



  async goToIssue(issue: ISSUE) {
    this.issueId  = issue.id;
    await this._authService.setIssue(this.issueId);
    this.router.navigate(['/issue-view']);
  }

  async getAllIssues() {

    const userVm = {
      id: this.user,
      token: this.token,
    };

    this.loading = true;
    await (await this._authService.getAllIssues(userVm)).subscribe(async data => {
      this.loading = false;
      console.log('getAllIssues data :>> ', data);
      data.forEach(element => {
        let eachIssue = {
          "id": element._id,
          "issueTitle" : element.issueTitle,
          // "team" : element["team"].name,
          "coach":element["coach"].name + " " +   element["coach"].surname,
          "solved":element.solved,
          "escalate":element.escalate,
          "creator":element.creator,
          "team":element.team,
          "created":element.createdAt
        };
        this.issueData.push(eachIssue);
      });
      console.log('issueData :>> ', this.issueData);
      return this.issueData;
    }, err => {
      console.log('issue error');
    });
  }

  async getAllIssuesCoach() {

    const userVm = {
      id: this.user,
      token: this.token,
    };

    this.loading = true;
    await (await this._authService.getAllIssuesCoach(userVm)).subscribe(async data => {
      this.loading = false;
      console.log('getAllIssues data :>> ', data);
      data.forEach(element => {
        let eachIssue = {
          "id": element._id,
          "issueTitle" : element.issueTitle,
          // "team" : element["team"].name,
          "coach":element["coach"].name + " " +   element["coach"].surname,
          "solved":element.solved,
          "escalate":element.escalate,
          "creator":element.creator,
          "team":element.team,
          "created":element.createdAt
        };
        this.issueData.push(eachIssue);
      });
      console.log('issueData :>> ', this.issueData);
      return this.issueData;
    }, err => {
      console.log('issue error');
    });
  }

  async getAllIssuesAdmin() {

    const userVm = {
      id: this.user,
      token: this.token,
    };

    this.loading = true;
    await (await this._authService.getAllIssuesAdmin(userVm)).subscribe(async data => {
      this.loading = false;
      console.log('getAllIssues data :>> ', data);
      data.forEach(element => {
      //   let date = new Date(element.createdAt);
      // date.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
        let eachIssue = {
          "id": element._id,
          "issueTitle" : element.issueTitle,
          // "team" : element["team"].name,
          "coach":element["coach"].name + " " +   element["coach"].surname,
          "solved":element.solved,
          "escalate":element.escalate,
          "creator":element.creator,
          "team":element.team,
          "created":element.createdAt
        };
        this.issueData.push(eachIssue);
      });
      console.log('issueData :>> ', this.issueData);
      
      return this.issueData;
    }, err => {
      console.log('issue error');
    });
  }
}