import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  Injectable
} from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import Chart from 'chart.js';
import { NgbActiveModal, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CreateEventComponent } from 'app/pages/calendar-area/create-event/create-event.component';
import * as FileSaver from "file-saver";
import { FILE } from 'app/pages/challenge-area/view-challenge/view-challenge.component';
import { UploadFileComponent } from 'app/pages/multimedia-area/upload-file/upload-file.component';
import { CreateProjectComponent } from 'app/pages/project-area/create-project/create-project.component';
import * as Highcharts from 'highcharts';
import { OnboardService } from 'app/onboard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { teamCharter } from './team-charter-questions';


@Component({
  selector: 'app-team-charter',
  templateUrl: './team-charter.component.html',
  styleUrls: ['./team-charter.component.css']
})


export class TeamCharterComponent implements OnInit {

  private subscriptionUser: Subscription;

  constructor(
    private _authService: AuthenticationService,
    private readonly _configService: ConfigService,
    private modalService: NgbModal,
    private router: Router,
    private tourService: OnboardService,
    private formBuilder: FormBuilder,
  ) { }

  isMobile: boolean = false;

  user: string = '';
  token: string = '';
  company: string = '';
  role: string = '';
  userTeamId: string = '';
  isUserManager: boolean = false;
  isUserTeamLeader: boolean = false;
  teamCharterQues = teamCharter;
  signOffState: boolean = false;


  charterform: FormGroup;
  loading = false;
  submitted = false;
  success = false;
  errorQuantity = '';
  errorSize = '';
  error = '';
  showError = false;
  errorMsg = '';
  closeResult = '';


  feedbackSuccess: boolean = false;
  feedbackAnswerSuccess: boolean = false;
  questionAsked: boolean = false;

  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  public async ngOnInit() {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.user = user['_id'];
      this.token = user['token'];
      this.company = user['company'];
      this.role = user['role'];
      this.userTeamId = user['teams'][0];
      this.isUserTeamLeader = user['leaderExp'] == true ? true : false

      // if (user['stats'])
      //   this.profileCompletition = user['stats'].profileCompletition;

      // if (this.profileCompletition == 100 && user['team']) this.id_team = user['team']._id;
      // else this.router.navigate(['/dashboard']);

      if (user['role'] == 'Admin' || user['role'] == 'CompanyAdmin' || user['role'] == 'Manager') this.isUserManager = true;
      else this.isUserManager = false;
    });
    this.charterform = this.formBuilder.group(this.formFields());

    await this.getFeedbackSuccess();

  }

  public formFields() {
    let empArr = {};
    for (let val of this.teamCharterQues) {
      let ques = val.name;
      empArr[ques] = ['', Validators.required];
    }
    return empArr;
  }

  get f() { return this.charterform.controls; }


  async onSubmit() {
    /* delete this bloc after demo video */
    this.success = true;
    this.Toast.fire({
      icon: 'success',
      title: 'You have successfully summitted the charter !'
    })

    // await this.router.navigate(['/team-overview']);

    /* end of delete this bloc after demo video */



    /* Imp - Below code works perfect uncomment after demo video */
    // this.submitted = true;
    // this.showError = false;
    // this.success = false;

    // // If there was an error in validation
    // if (this.charterform.invalid) return;
    // // Disable the button and show the spinner
    // this.loading = true;


    // let userAnswers = [];
    // let charterVm = {
    //   id: this.user,
    //   token: this.token,
    //   name: "Team Charter 1",
    //   team: this.userTeamId["team"]
    // };

    // for (let val of this.teamCharterQues) {
    //   let ques = val.name;
    //   let element = { "name": ques, "question": val.question, "description": val.description, "answer": this.f[ques].value }
    //   userAnswers.push(element);
    // }

    // charterVm["charter"] = userAnswers

    // await (await this._authService.createCharter(charterVm))
    //   .pipe(first()).subscribe(data => {
    //     this.loading = false;
    //     this.submitted = false;
    //     this.success = true;
    //   }, err => {
    //     this.showError = true;
    //     // this.error = err || 'There was an error';
    //     this.errorMsg = err.error.message;
    //     this.loading = false;
    //     this.submitted = false;
    //   })

  }

  async goToOutput() {
    await this.router.navigate(['/team-overview']);
  }

  async getFeedbackSuccess() {
    this.feedbackSuccess = localStorage.getItem('feedbackSuccess') == "true" ? true : false;
    this.feedbackAnswerSuccess = localStorage.getItem('feedbackAnswerSuccess') == "true" ? true : false;
    this.questionAsked = localStorage.getItem('questionAsked') == "true" ? true : false;

    // localStorage.setItem('feedbackSuccess', "false");
    // localStorage.setItem('feedbackAnswerSuccess', "false");
    // localStorage.setItem('questionAsked', "false");

  }

  async setQuestion() {
    localStorage.setItem('questionAsked', "true");
    this.Toast.fire({
      icon: 'success',
      title: 'Your request has been sent successfully !'
    })
  }
}