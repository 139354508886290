import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-chat',
  templateUrl: './team-chat.component.html',
  styleUrls: ['./team-chat.component.css'],
})
export class TeamChatComponent implements OnInit {

  threadItems = [
    {
      avatar: "../../assets/img/chat1.png",
      message: "Hi, thanks for adding me @Trish. Looking forward to working on this?",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all, just thinking this Shawn might be good to loop in on this too!",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat3.png",
      message: "Great idea @Glen, I'll add him now. We should look at dates for a quick meeting re the description, aims and goals.",
      sender: true,
    },
    {
      avatar: "../../assets/img/chat1.png",
      message: "Great idea - how's the 12th for everyone?",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat1.png",
      message: "Hi, thanks for adding me @Trish. Looking forward to working on this?",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all, just thinking this Shawn might be good to loop in on this too!",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat3.png",
      message: "Great idea @Glen, I'll add him now. We should look at dates for a quick meeting re the description, aims and goals.",
      sender: true,
    },
    {
      avatar: "../../assets/img/chat1.png",
      message: "Great idea - how's the 12th for everyone?",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat1.png",
      message: "Hi, thanks for adding me @Trish. Looking forward to working on this?",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all, just thinking this Shawn might be good to loop in on this too!",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat3.png",
      message: "Great idea @Glen, I'll add him now. We should look at dates for a quick meeting re the description, aims and goals.",
      sender: true,
    },
    {
      avatar: "../../assets/img/chat1.png",
      message: "Great idea - how's the 12th for everyone?",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat1.png",
      message: "Hi, thanks for adding me @Trish. Looking forward to working on this?",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all, just thinking this Shawn might be good to loop in on this too!",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat3.png",
      message: "Great idea @Glen, I'll add him now. We should look at dates for a quick meeting re the description, aims and goals.",
      sender: true,
    },
    {
      avatar: "../../assets/img/chat1.png",
      message: "Great idea - how's the 12th for everyone?",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat1.png",
      message: "Hi, thanks for adding me @Trish. Looking forward to working on this?",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat2.png",
      message: "Hi all, just thinking this Shawn might be good to loop in on this too!",
      sender: false,
    },
    {
      avatar: "../../assets/img/chat3.png",
      message: "Great idea @Glen, I'll add him now. We should look at dates for a quick meeting re the description, aims and goals.",
      sender: true,
    },
    {
      avatar: "../../assets/img/chat1.png",
      message: "Great idea - how's the 12th for everyone?",
      sender: false,
    }
  ];

  constructor(private router: Router) { }

  checked: boolean = false;

  ngOnInit(): void {
    console.log(this.threadItems)

    if(localStorage.getItem('newMsg')){
      console.log(localStorage.getItem('newMsg'))
      let theMsg = JSON.parse(localStorage.getItem('newMsg'));
      console.log(theMsg)
      this.threadItems.push(theMsg)
      this.checked = true;
    }else{
      this.checked = true;
    }
  }

  newMsg;

  addMsg() {
    let msgObj = {
      avatar: "../../assets/img/chat3.png",
      message: this.newMsg,
      sender: true,
    }
    
    // this.threadItems.push(msgObj)

    // console.log(this.threadItems)

    localStorage.setItem('newMsg', JSON.stringify(msgObj))

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/chat']);
  }); 
  }

}
