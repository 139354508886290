import {
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { StoreProvider } from 'providers/store/store';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-apologies',
  templateUrl: './apologies.component.html',
  styleUrls: ['./apologies.component.css'],
})
export class ApologiesComponent implements OnInit {

  private subscriptionUser: Subscription;

  user = {
    id: '',
    token: '',
    name: '',
    surname: '',
    fullName: '',
    email: '',
    avatar: '',
    company: '',
    industry: '',
    companyRole: '',
    knowledge: '',
    skills: '',
    bio: [''],
    strengthsAndCompetencies: '',
    hobbies: '',
    interests: '',
    emailVerification: null,
    learnNewSkills: '',
    yearsCurrentRole: '',
    yearsSimilarRole: '',
    yearsPreviousRole: '',
    age: '',
    gender: '',
    ethnicity: '',
    leaderExp: '',
  };

  id: string = '';
  token: string = '';
  loadingIndicator = false;

  isMobile: boolean = false;
  theUser;
  onboarded: Boolean = false;
  role;
  recieved = [];
  sent = [];
  replies = [];

  constructor(
    private _authService: AuthenticationService,
    private storage: StoreProvider,
    private router: Router,
    private route: ActivatedRoute,
    private readonly _configService: ConfigService,) { }

  async ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      // console.log(value)
      let user: any[] = value ? JSON.parse(value) : {}
      console.log(user)
      this.id = user['_id'];
      this.token = user['token'];
      this.role = user['role'];
      this.recieved = user['recievedMessages'];
      this.sent = user['sentMessages'];
      this.replies = user['replies'];

      console.log(this.replies)
      console.log(this.sent)

      for (let i = 0; i <= this.replies.length - 1; i++) {
        this.replies[i] = JSON.parse(this.replies[i])
      }


      for (let i = 0; i <= this.recieved.length - 1; i++) {
        this.recieved[i] = JSON.parse(this.recieved[i])
        this.recieved[i].reply = [];
        let date = new Date(this.recieved[i].timestamp)
        date.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" });
        this.recieved[i].timestamp = date;
      }

      for (let i = 0; i <= this.sent.length - 1; i++) {
        this.sent[i] = JSON.parse(this.sent[i]);
        this.sent[i].reply = [];
        let date = new Date(this.sent[i].timestamp)
        date.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" });
        this.sent[i].timestamp = date;

      }

      this.sent.sort((d1, d2) => new Date(d2.timestamp).getTime() - new Date(d1.timestamp).getTime());
      this.recieved.sort((d1, d2) => new Date(d2.timestamp).getTime() - new Date(d1.timestamp).getTime());



      for (let i = 0; i <= this.sent.length - 1; i++) {
        for (let x = 0; x <= this.replies.length - 1; x++) {
          if (this.sent[i]._id == this.replies[x].messageId) {
            console.log(true)
            let date = new Date(this.replies[x].timestamp)
            date.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" });
            this.replies[x].timestamp = date;
            this.sent[i].reply.push(this.replies[x]);
            // JSON.parse(this.sent[i].reply)
          } else {
            console.log(false)
          }
        }
      }

      for (let i = 0; i <= this.recieved.length - 1; i++) {
        for (let x = 0; x <= this.replies.length - 1; x++) {
          if (this.recieved[i]._id == this.replies[x].messageId) {
            console.log(true)
            let date = new Date(this.replies[x].timestamp)
            date.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" });
            this.replies[x].timestamp = date;
            this.recieved[i].reply.push(this.replies[x]);
            // JSON.parse(this.sent[i].reply)
          } else {
            console.log(false)
          }
        }
      }

      // this.sent.reply.sort((d1, d2) => new Date(d2.timestamp).getTime() - new Date(d1.timestamp).getTime());
      // this.recieved.reply.sort((d1, d2) => new Date(d2.timestamp).getTime() - new Date(d1.timestamp).getTime());


      console.log(this.recieved)
      console.log(this.sent);
      console.log(this.replies)

    });

    // await this.getUserProfile();
    await this.getMyTeam();
  }

  theRecipient;
  isSelected: boolean = false;

  recipientMsg;
  msgTitle;
  msgText;

  setRecipient(user){
    this.theRecipient = user;
    console.log(this.theRecipient)
    this.isSelected = true;
  }

  async sendMessage() {

    console.log(this.recipientMsg)
    console.log(this.msgTitle)
    console.log(this.msgText)

    let MessageVm = {
      id: this.id,
      token: this.token,
      sender: this.id,
      recipient: this.theRecipient,
      title: this.msgTitle.toString(),
      message: this.msgText.toString()
    }



    let x = (await this._authService.sendMessage(MessageVm)).subscribe(async res => {

    })


  }

  replyVal: Boolean = false;
  replyMsg;

  async sendReply(messageId) {

    let MessageVm2 = {
      id: this.id,
      token: this.token,
      sender: this.id,
      message: this.replyMsg.toString(),
      messageId: messageId._id,
      recipient: undefined
    }
    if (messageId.rid == undefined) {
      MessageVm2.recipient = messageId.sid;
    } else {
      MessageVm2.recipient = messageId.rid;
    }


    console.log(MessageVm2)
    let x = (await this._authService.sendReply(MessageVm2)).subscribe(async res => {

    })
  }

  reply() {
    if (this.replyVal == true) {
      this.replyVal = false;
    } else {
      this.replyVal = true;
    }
  }

  usersName: string = null;
  teamCoach;

  async getMyTeam() {


    let userVm = {
      id: this.id,
      token: this.token,
    }

    let x = (await (this._authService.getAllMyTeams(userVm))).subscribe(async res => {
      console.log(res)
      this.setRecipient(res[0].coach)
    })

  }


}
