import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { ConfigService } from 'providers/config/config.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'providers/authentication.service';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Router } from '@angular/router';

export interface IDEA {
  id: string;
  title: string;
  text: string[];
  index?: string;
  // shortTitle: string;
  creator: string;
  creator_id: string;
  // originalTitle: string;
  flagged: boolean;
  skills: [];
  createdAt: Date;
  timeFromNow: string;
  likes: number;
  challenge: string;
  img: string;
}

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.css']
})
export class ShowcaseComponent implements OnInit {


  private subscriptionUser: Subscription;

  user: string = '';
  token: string = '';
  preonboarded: Boolean = false;
  theUser: any;
  allIdeas;
  theIdeas;
  isMobile;

  filteredLikes;
  likedIdeas;

  ideasLength;
  isOdd: boolean = false;

  showcaseTab: boolean = true;
  recognitionsTab: boolean = false;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private _authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router) { }

    counter = 0;
  
    newImg(){
      console.log(this.counter)
      this.counter++;
      if(this.counter == 0){
        return 'https://fastly.picsum.photos/id/628/382/163.jpg?hmac=gPwc26oUYc2srIpbMNLy_EK1ceMYAljhBoLmfdh6qzI';
      }else if(this.counter == 1){
        return 'https://fastly.picsum.photos/id/442/382/163.jpg?hmac=CvQiw4ScjnKsa3GI6ywn76aVFlAO7UVAgkoO_xDl4fs';
      }else if(this.counter == 2){
        return 'https://fastly.picsum.photos/id/350/382/163.jpg?hmac=k5Qcnwm2KBsbCyo5NfnqpXX-RNEtlT689jn-SSG8uSo';
      }else if(this.counter == 3){
        return 'https://fastly.picsum.photos/id/412/382/163.jpg?hmac=Xl3nn1Dq6MMWtuiDSftFVsM-dgZWoGBEMtJ3O_UwydM';
      }else if(this.counter == 4){
        return 'https://fastly.picsum.photos/id/727/382/163.jpg?hmac=HHtPyzXRZsUTF7K6Srto_VaQJBLRnhMxq2xfXqZ4fhU';
      }else if(this.counter == 5){
        this.counter = 0;
        return 'https://fastly.picsum.photos/id/249/382/163.jpg?hmac=yG6E-Kw6vOhDCxqYM8lvILkiEH_gU9bKsouuAzfCzPc';
      }
  
    }

  ngOnInit(): void {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      const user: any[] = value ? JSON.parse(value) : {};
      this.theUser = user;
      // console.log(user)
      // console.log(this.theUser)
      this.user = user['_id'];
      this.token = user['token'];
      this.likedIdeas = user['likedProjects'];
    });

    console.log(this.likedIdeas)

    this.http.post(this.config.url + 'idea/getAllIdeasGlobal', { id: this.user, token: this.token }).subscribe(res => {
      console.log(res)
      this.allIdeas = [];
      this.theIdeas = res;

      console.log(this.theIdeas)

      for (let index = 0; index < this.theIdeas.length; index++) {
        let img = this.newImg();
        const idea = this.theIdeas[index];
        let created = new Date(idea.createdAt);
        idea.createdAt = created.toDateString().split(' ').slice(1).join(' ');
        if (idea.adminApproved) {
          this.allIdeas.push({
            id: idea._id,
            // shortTitle: idea.title.split("<br>")[0].length > 10 ? idea.title.split("<br>")[0].substring(0, 10) : idea.title.split("<br>")[0],
            // shortTitle: idea.title.replace(/<br>/g, '\n').length > 100 ? idea.title.replace(/<br>/g, '\n').substring(0, 100) : idea.title.replace(/<br>/g, '\n'),
            title: idea.title,
            // text: idea.text.split("<br>"),
            text: idea.text.split("<br>"),
            createdAt: idea.createdAt,
            flagged: idea.flagged,
            skills: idea.skills,
            creator: idea.creator.name + ' ' + idea.creator.surname,
            creator_id: idea.creator._id,
            timeFromNow: this.transform(idea.createdAt),
            likes: idea.likes,
            adminApproved: idea.adminApproved,
            challenge: idea.challenge,
            img: img
          })
        }


        // this.likeCount.push(this.ideasRecords[index].likes)
      }
      this.allIdeas.sort(function (a, b) {
        return parseFloat(b.likes) - parseFloat(a.likes);
      });

      console.log(this.allIdeas)
      this.ideasLength = this.allIdeas.length;
      if(this.ideasLength % 2 == 1){
        this.isOdd = true;
      }

      // console.log(this.filteredLikes)
      // console.log(this.likedIdeas)

      this.allIdeas.forEach(array1Ttem => {

        this.likedIdeas.forEach(array2Item => {

          if (array1Ttem.id == array2Item) {
            // console.log("It's match", array1Ttem);
            array1Ttem.liked = true;

          }
          else {
            // console.log("This item not present in array =>",array1Ttem);
            // array1Ttem.liked = false;
          }

        })
      })

    })
  }

  transform(value: string) {
    let now = moment(value).fromNow();
    return now;
  }

  async goToIdea(idea: IDEA) {
    console.log(idea)
    await this._authService.setIdea(idea.id);
    await this._authService.setChallenge(idea.challenge);
    this.router.navigate(['/idea-view']);
  }

  

  async addLike(idea: IDEA) {

    // console.log("LIKED")
    // console.log(idea)

    let ideaVm = {
      id: this.user,
      token: this.token,
      idea: idea.id
    };

    // this.loading = true;
    let x;

    for (let i = 0; i <= this.allIdeas.length - 1; i++) {
      if (this.allIdeas[i].id == idea.id) {
        x = i;
      }
    }

    return await (await this._authService.addLike(ideaVm)).subscribe(async data => {
      this.allIdeas[x].liked = true;
      this.allIdeas[x].likes++;
      // await this.getAllIdeas();
      this.allIdeas.sort(function (a, b) {
        return parseFloat(b.likes) - parseFloat(a.likes);
      });
    })
  }

  async removeLike(idea: IDEA) {
    // console.log("UNLIKED")
    // console.log(idea)

    let ideaVm = {
      id: this.user,
      token: this.token,
      idea: idea.id
    };

    // this.loading = true;
    let x;

    for (let i = 0; i <= this.allIdeas.length - 1; i++) {
      if (this.allIdeas[i].id == idea.id) {
        x = i;
      }
    }

    return await (await this._authService.removeLike(ideaVm)).subscribe(async data => {
      this.allIdeas[x].liked = false;
      this.allIdeas[x].likes--;
      // await this.getAllIdeas();
      this.allIdeas.sort(function (a, b) {
        return parseFloat(b.likes) - parseFloat(a.likes);
      });
    })
  }

  switchTabsMobile(){
    if(this.showcaseTab == false){
      this.showcaseTab = true;
      this.recognitionsTab = false;
    }else if(this.recognitionsTab == false){
      this.recognitionsTab = true;
      this.showcaseTab = false;
    }
  }

  switchToRecognitions(){

  }

}

