import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OnboardService {

  private unenRollDash: Boolean = false;
  private unenRollAv: Boolean = false;
  private unenRollProfile: Boolean = false;

  private enrollChallenges: Boolean = false;
  private enrollOneChallenge: Boolean = false;
  private enrollProjects: Boolean = false;
  
  private enrollSubmitProj: Boolean = false;

  private enrollMyTeams: Boolean = false;
  private enrollCoaching: Boolean = false;
  private enrollLearning: Boolean = false;
  private enrollCalendar: Boolean = false;

  private teamOverview: Boolean = false;

  private theUser;

  private enrollManagerDash1: Boolean = false;
  private enrollManagerDash2: Boolean = false;
  private enrollManagerDash3: Boolean = false;

  private managerChallengeDraft: Boolean = false;
  private managerChallengeDraftTeam: Boolean = false;

  private managerProjects: Boolean = false;

  private teamOutputs: Boolean = false;

  private coachingOverview: Boolean = false;

  private learningAndCoaching: Boolean = false;

  setLearningAndCoaching(value){
    this.learningAndCoaching = value;
  }

  setCoachingOverview(value){
    this.coachingOverview = value;
  }

  setTeamOutputs(value){
    this.teamOutputs = value;
  }

  setManagerProjects(value){
    this.managerProjects = value;
  }

  setManagerChallengeDraft(value){
    this.managerChallengeDraft = value;
  }

  setManagerChallengeDraftTeam(value){
    this.managerChallengeDraftTeam = value;
  }

  setEnrollManagerDash1(value){
    this.enrollManagerDash1 = value;
  }

  setEnrollManagerDash2(value){
    this.enrollManagerDash2 = value;
  }

  setEnrollManagerDash3(value){
    this.enrollManagerDash3 = value;
  }

  setUser(user){
    this.theUser = user;
  }

  getUser(){
    return this.theUser;
  }

  setUnenRollDash(value){
    this.unenRollDash = value;
  }

  setUnenRollAv(value){
    this.unenRollAv = value;
    
  }

  setUnenRollProfile(value){
    this.unenRollProfile = value;
  } 
  
  setEnrollChallenges(value){
    this.enrollChallenges = value;
  }

  setEnrollOneChallenge(value){
    this.enrollOneChallenge = value;
  } 
  
  setEnrollProjects(value){
    this.enrollProjects = value;
  }  
  
  setEnrollSubmitProj(value){
    this.enrollSubmitProj = value;
  }  
  
  setEnrollTeams(value){
    this.enrollMyTeams = value;
  }

  setEnrollCoaching(value){
    this.enrollCoaching = value;
  }

  setEnrollLearning(value){
    this.enrollLearning = value;
  }
  
  
  setEnrollCalendar(value){
    this.enrollCalendar = value;
  }
  
  setTeamOverview(value){
    this.teamOverview = value;
  }

  getUnenRollDash(){
    return this.unenRollDash;
  }

  getUnenRollAv(){
    return this.unenRollAv;
  }

  getUnenRollProfile(){
    return this.unenRollProfile;
  }

  getEnrollChallenges(){
    return this.enrollChallenges;
  }

  getEnrollOneChallenge(){
    return this.enrollOneChallenge;
  }

  getEnrollProjects(){
    return this.enrollProjects;
  }

  getEnrollSubmitProj(){
    return this.enrollSubmitProj;
  }

  getEnrollTeams(){
    return this.enrollMyTeams;
  }

  getEnrollCoaching(){
    return this.enrollCoaching;
  }

  getEnrollLearning(){
    return this.enrollLearning;
  } 

  getEnrollCalendar(){
    return this.enrollCalendar;
  }

  getTeamOverview(){
    return this.teamOverview;
  }

  getEnrollManagerDash1(){
    return this.enrollManagerDash1;
  }

  getEnrollManagerDash2(){
    return this.enrollManagerDash2;
  }

  getEnrollManagerDash3(){
    return this.enrollManagerDash3;
  }

  getManagerChallengeDraft(){
    return this.managerChallengeDraft;
  }

  getManagerChallengeDraftTeam(){
    return this.managerChallengeDraftTeam;
  }

  getManagerProjects(){
    return this.managerProjects;
  }

  getTeamOutputs(){
    return this.teamOutputs;
  }
  
  getCoachingOverview(){
    return this.coachingOverview;
  }

  getLearningAndCoaching(){
    return this.learningAndCoaching;
  }

  constructor() { }
}
