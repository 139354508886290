import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

export interface IDEA {
  id: string;
  title: string;
  text: string[];
  index?: string;
  creator: string;
  creator_id: string;
  flagged: boolean;
  skills: [];
  createdAt: Date;
  timeFromNow: string;
  likes: number;
  adminApproved: boolean;
}

@Component({
  selector: 'app-company-admin-approve-projects',
  templateUrl: './company-admin-approve-projects.component.html',
  styleUrls: ['./company-admin-approve-projects.component.css']
})
export class CompanyAdminApproveProjectsComponent implements OnInit {

  isUserAMember: boolean = false;

  user: string = '';
  token: string = '';
  id_challenge: string = '';

  admin: boolean = true;
  isChallengeExpired: boolean = false;
  allIdeasRecords: any;

  private subscriptionUser: Subscription;

  success = false;
  loading = false;
  submitted = false;
  showError = false;


  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(
    private _authService: AuthenticationService,
    private readonly _configService: ConfigService,
    private modalService: NgbModal,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  public async ngOnInit() {
    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.user = user['_id'];
      this.token = user['token'];

      if (user['company'] && (user['role'] == 'Admin' || user['role'] == 'CompanyAdmin')) this.admin = true;
      else this.admin = false;

      if (!this.admin) this.router.navigate(['/dashboard']);

    });

    this.id_challenge = await this._authService.getChallenge();
    if (!this.id_challenge) this.router.navigate(['/previous-challenges']);

    this.getAllIdeas();
  }

  async getAllIdeas() {
    this.allIdeasRecords = [];
    const ideaVm = {
      id: this.user,
      token: this.token,
      challenge: this.id_challenge,
      skip: 0,
    };

    await (await this._authService.getAllChallengeIdeas(ideaVm)).subscribe(newData => {
      this.allIdeasRecords = [];

      newData.forEach(element => {
        let idea = { "id": element._id, "title": element.title, "text": element.text, "adminApproved": element.adminApproved }
        this.allIdeasRecords.push(idea);
      });
    }, err => {
      console.log('error');
    });
  }

  async goToIdea(id) {
    await this._authService.setIdea(id);
    this.router.navigate(['/disapprove-projects']);
  }
}