import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  Injectable
} from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import Chart from 'chart.js';
import { NgbActiveModal, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CreateEventComponent } from 'app/pages/calendar-area/create-event/create-event.component';
import * as FileSaver from "file-saver";
import { FILE } from 'app/pages/challenge-area/view-challenge/view-challenge.component';
import { UploadFileComponent } from 'app/pages/multimedia-area/upload-file/upload-file.component';
import { CreateProjectComponent } from 'app/pages/project-area/create-project/create-project.component';
import * as Highcharts from 'highcharts';
import { OnboardService } from 'app/onboard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-charter-signed-members',
  templateUrl: './charter-signed-members.component.html',
  styleUrls: ['./charter-signed-members.component.css']
})
export class CharterSignedMembersComponent implements OnInit {


  private subscriptionUser: Subscription;

  constructor(
    private _authService: AuthenticationService,
    private readonly _configService: ConfigService,
    private modalService: NgbModal,
    private router: Router,
    private tourService: OnboardService,
  ) { }

  isMobile: boolean = false;

  user: string = '';
  token: string = '';
  company: string = '';
  role: string = '';
  userTeamId: string = '';
  isUserManager: boolean = false;
  isUserTeamLeader: boolean = false;
  signOffState: boolean = false;


  loading = false;
  submitted = false;
  success = false;
  errorQuantity = '';
  errorSize = '';
  error = '';
  showError = false;
  errorMsg = '';
  closeResult = '';

  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  public async ngOnInit() {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.user = user['_id'];
      this.token = user['token'];
      this.company = user['company'];
      this.role = user['role'];
      this.userTeamId = user['teams'][0];
      this.isUserTeamLeader = user['leaderExp'] == true ? true : false

      // if (user['stats'])
      //   this.profileCompletition = user['stats'].profileCompletition;

      // if (this.profileCompletition == 100 && user['team']) this.id_team = user['team']._id;
      // else this.router.navigate(['/dashboard']);

      if (user['role'] == 'Admin' || user['role'] == 'CompanyAdmin' || user['role'] == 'Manager') this.isUserManager = true;
      else this.isUserManager = false;
    });

  }

  async goToOutput() {
    await this.router.navigate(['/team-overview']);
  }

  async submitMembers() {
    this.Toast.fire({
      icon: 'success',
      title: 'Your request has been sent successfully !'
    })
  }
}