import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import { Subscription } from 'rxjs';

var misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}
//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/admin',
        title: 'Administration Centre',
        type: '',
        icontype: 'fas fa-user-shield'
    },
    {
        path: '/company-admin',
        title: 'Company Centre',
        type: '',
        icontype: 'fas fa-tools'
    },
    {
        path: '/coach-dashboard',
        title: 'Coach Dashboard',
        type: '',
        icontype: 'fas fa-chalkboard-teacher'
    },
    // {
    //     path: '/admin-users',
    //     title: 'Administration Centre - Users',
    //     type: '',
    //     icontype: ''
    // },
    // {
    //     path: '/admin-teams',
    //     title: 'Administration Centre - Teams',
    //     type: '',
    //     icontype: ''
    // },
    // {
    //     path: '/admin-team-profile',
    //     title: 'Administration Centre - Team Profile',
    //     type: '',
    //     icontype: ''
    // },
    {
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        // icontype: 'fas fa-desktop'
        icontype: 'fab fa-trello'
        // icontype: 'fas fa-th'
    },
    {
        path: '/challenge-view-list',
        title: 'Challenges',
        type: 'link',
        icontype: 'fa-regular fa-calendar-check'
    },
    {
        path: '/cmg',
        title: 'Challenge Management Group',
        type: 'link',
        icontype: 'fa-solid fa-chalkboard-user fa-flip-horizontal'
    },
    {
        path: '/projects',
        title: 'Projects',
        type: 'link',
        icontype: 'fa-solid fa-bolt'
    },
{
        path: '/team-overview',
        title: 'Teams',
        type: 'link',
        icontype: 'fa-solid fa-user-group'
    },
    {
        path: '/lms',
        title: 'Users',
        type: 'link',
        icontype: 'fa-regular fa-user'
    },
{
        path: '/lms',
        // title: 'Learning Management System',
        title: 'Learning',
        type: 'link',
        icontype: 'fa-solid fa-list-check'
    },
    // {
    //     path: '/information',
    //     title: 'Learning',
    //     type: 'link',
    //     icontype: 'fa-solid fa-list-check'
    // },
    {
        path: '/coaching-overview',
        title: 'Coaching',
        type: 'link',
        icontype: 'fa-regular fa-message'
    },
    {
        path: '/lms',
        title: 'Engagement',
        type: 'link',
        icontype: 'fa-solid fa-chart-simple'
    },
    {
        path: '/showcase',
        title: 'Showcase',
        type: 'link',
        icontype: 'fa-regular fa-lightbulb'
    },
    {
        path: '/lms',
        title: 'Favourites',
        type: 'link',
        icontype: 'fa-solid fa-star'
    },
    // {
    //     path: '/profile-settings',
    //     title: 'User Profile',
    //     type: 'link',
    //     icontype: 'fas fa-user'
    // },
    // {
    //     path: '/lms',
    //     // title: 'Learning Management System',
    //     title: 'LMS',
    //     type: 'link',
    //     icontype: 'fas fa-book'
    // },
    // {
    //     path: '/information',
    //     title: 'Information Resources',
    //     type: 'link',
    //     icontype: 'fas fa-info-circle'
    // },
    // {
    //     path: '/calendar',
    //     title: 'Event Calendar',
    //     type: 'link',
    //     icontype: 'fas fa-calendar-day'
    // },
    // {
    //     path: '/my-challenges',
    //     title: 'My Challenges',
    //     type: '',
    //     icontype: 'fas fa-file-invoice'
    // },
    // {
    //     path: '/my-teams',
    //     title: 'My Teams',
    //     type: '',
    //     icontype: 'fas fa-users'
    // }
    // ,
    // {
    //     path: '/ideas',
    //     title: 'Ideas',
    //     type: 'link',
    //     icontype: 'fas fa-lightbulb-on'
    // },
    // {
    //     path: '/ideas-flagging',
    //     title: 'Flagging Ideas',
    //     type: 'link',
    //     icontype: 'fas fa-flag'
    // },
    // {
    //     path: '/ideas-ranking',
    //     title: 'Ranking Ideas',
    //     type: 'link',
    //     icontype: 'fas fa-star'
    // }

    // , {
    //     path: '/components',
    //     title: 'Components',
    //     type: 'sub',
    //     collapse: 'components',
    //     icontype: 'nc-icon nc-layout-11',
    //     children: [
    //         { path: 'buttons', title: 'Buttons', ab: 'B' },
    //         { path: 'grid', title: 'Grid System', ab: 'GS' },
    //         { path: 'panels', title: 'Panels', ab: 'P' },
    //         { path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA' },
    //         { path: 'notifications', title: 'Notifications', ab: 'N' },
    //         { path: 'icons', title: 'Icons', ab: 'I' },
    //         { path: 'typography', title: 'Typography', ab: 'T' }
    //     ]
    // }, {
    //     path: '/forms',
    //     title: 'Forms',
    //     type: 'sub',
    //     collapse: 'forms',
    //     icontype: 'nc-icon nc-ruler-pencil',
    //     children: [
    //         { path: 'regular', title: 'Regular Forms', ab: 'RF' },
    //         { path: 'extended', title: 'Extended Forms', ab: 'EF' },
    //         { path: 'validation', title: 'Validation Forms', ab: 'VF' },
    //         { path: 'wizard', title: 'Wizard', ab: 'W' }
    //     ]
    // }, {
    //     path: '/tables',
    //     title: 'Tables',
    //     type: 'sub',
    //     collapse: 'tables',
    //     icontype: 'nc-icon nc-single-copy-04',
    //     children: [
    //         { path: 'regular', title: 'Regular Tables', ab: 'RT' },
    //         { path: 'extended', title: 'Extended Tables', ab: 'ET' },
    //         { path: 'datatables.net', title: 'Datatables.net', ab: 'DT' }
    //     ]
    // }, {
    //     path: '/maps',
    //     title: 'Maps',
    //     type: 'sub',
    //     collapse: 'maps',
    //     icontype: 'nc-icon nc-pin-3',
    //     children: [
    //         { path: 'google', title: 'Google Maps', ab: 'GM' },
    //         { path: 'fullscreen', title: 'Full Screen Map', ab: 'FSM' },
    //         { path: 'vector', title: 'Vector Map', ab: 'VM' }
    //     ]
    // }, {
    //     path: '/widgets',
    //     title: 'Widgets',
    //     type: 'link',
    //     icontype: 'nc-icon nc-box'

    // }, {
    //     path: '/charts',
    //     title: 'Charts',
    //     type: 'link',
    //     icontype: 'nc-icon nc-chart-bar-32'

    // }, {
    //     path: '/calendar',
    //     title: 'Calendar',
    //     type: 'link',
    //     icontype: 'nc-icon nc-calendar-60'
    // }, {
    //     path: '/pages',
    //     title: 'Pages',
    //     collapse: 'pages',
    //     type: 'sub',
    //     icontype: 'nc-icon nc-book-bookmark',
    //     children: [
    //         { path: 'timeline', title: 'Timeline Page', ab: 'T' },
    //         { path: 'profile', title: 'User Profile', ab: 'UP' },
    //         { path: 'login', title: 'Login Page', ab: 'LP' },
    //         { path: 'register', title: 'Register Page', ab: 'RP' },
    //         { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' }
    //     ]
    // }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit, OnDestroy {

    public menuItems: any[];
    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    private subscriptionUser: Subscription;

    user = {
        id: '',
        token: '',
        fullName: '',
        email: '',
        avatar: '',
        emailVerification: null,
        profileCompletition: 0
    };

    // isProfileComplete: boolean = false;

    constructor(
        private _authService: AuthenticationService,
        private readonly _configService: ConfigService
    ) { }

    ngOnInit() {
        
        this.menuItems = ROUTES.filter(menuItem => menuItem);

        this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
            let user: any[] = value ? JSON.parse(value) : {}
            this.user.id = user['_id'];
            this.user.token = user['token'];
            this.user.fullName = user['name'] + ' ' + user['surname'];
            this.user.email = user['email'];
            this.user.emailVerification = user['emailVerification'];
            if (user['stats'])
                this.user.profileCompletition = user['stats'].profileCompletition;

            const objIndexTeam = this.menuItems.findIndex((obj => obj.path == '/team-overview'));
            const objIndexCompanyAdmin = this.menuItems.findIndex((obj => obj.path == '/company-admin'));
            const objIndexCompanyChallenges = this.menuItems.findIndex((obj => obj.path == '/challenge-view-list'));

            // const objIndexIdea = this.menuItems.findIndex((obj => obj.path == '/ideas'));
            // const objIndexIdeaFlagging = this.menuItems.findIndex((obj => obj.path == '/ideas-flagging'));
            // const objIndexIdeaRanking = this.menuItems.findIndex((obj => obj.path == '/ideas-ranking'));
            console.log(this.menuItems[objIndexTeam])

            if (user['company']) {
                if (this.user.profileCompletition == 100 && user['teams'].length > 0) this.menuItems[objIndexTeam].type = 'link';
                else this.menuItems[objIndexTeam].type = '';

                if (user['role'] == 'Admin' || user['role'] == 'CompanyAdmin') this.menuItems[objIndexCompanyAdmin].type = 'link';
                else this.menuItems[objIndexCompanyAdmin].type = '';

                this.menuItems[objIndexCompanyChallenges].type = 'link';
                // this.menuItems[objIndexIdeaFlagging].type = 'link';
                // this.menuItems[objIndexIdeaRanking].type = 'link';
            } else {
                this.menuItems[objIndexTeam].type = '';
                this.menuItems[objIndexCompanyAdmin].type = '';
                this.menuItems[objIndexCompanyChallenges].type = '';

                // this.menuItems[objIndexIdea].type = '';
                // this.menuItems[objIndexIdeaFlagging].type = '';
                // this.menuItems[objIndexIdeaRanking].type = '';
            }

            const objIndexAdmin = this.menuItems.findIndex((obj => obj.path == '/admin'));
            if (user['role'] == 'Admin') this.menuItems[objIndexAdmin].type = 'link';
            else this.menuItems[objIndexAdmin].type = '';

            const objIndexCoachDashboard = this.menuItems.findIndex((obj => obj.path == '/coach-dashboard'));
            if (user['role'] == 'Coach') this.menuItems[objIndexCoachDashboard].type = 'link';
            else this.menuItems[objIndexCoachDashboard].type = '';

            // if (this.user.emailVerification) {
            //     this.privilege = user['privilege'];
            //     if (user['role'] == 'Admin') this.admin = true;
            //     else this.admin = false;
            // }

            // this.showUnverifiedEmail = !this.user.emailVerification;
            this.user.avatar = this._configService.url + user['avatar'];

        });

    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    ngOnDestroy() {
        this.subscriptionUser.unsubscribe();
    }

}