import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EventEmitterService {

  public eventOnChange: EventEmitter<any> = new EventEmitter();

  // public doSomething(message: string) {
  //     // do something, then...
  //     this.onChange.emit({message: message, eventId: 42});
  // }

}
