import { StoreProvider } from './../providers/store/store';
import { filter, first } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Plugins, Capacitor } from '@capacitor/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'providers/authentication.service';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
const { Modals } = Plugins;

export const VERSION: string = '1.0.0';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'AMPloyees';
  URL: string = null;

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  private pageSubscription: Subscription;

  constructor(private storage: StoreProvider,
    public router: Router,
    private ccService: NgcCookieConsentService,
    private _authService: AuthenticationService) {

    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-LQ4H9ML5P2'), { 'page_path': event.urlAfterRedirects, }
    })
  }

  async ngOnInit() {

    this.pageSubscription = await this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(e => {
      const navigation = this.router.getCurrentNavigation();
      if (navigation.extractedUrl.root.children.primary)
        this.URL = navigation.extractedUrl.root.children.primary.segments[0].path;
    });

    this.pageSubscription.unsubscribe();

    if (Capacitor.getPlatform() == 'android' || Capacitor.getPlatform() == 'ios') await this.pushNotification();

    await this.checkToken();
  }

  async checkToken() {
    await this.storage.getItem('token').then(
      async token => {
        if (token != null) {
          let id = await this.storage.getItem('id');
          let firebaseToken = null;
          if (Capacitor.getPlatform() == 'android' || Capacitor.getPlatform() == 'ios') firebaseToken = await this.storage.getItem('firebaseToken');
          let tokenLogin = {
            id: id,
            token: token,
            firebaseToken: firebaseToken
          };
          if (this.URL)
            await (await this._authService.tokenDashboard(tokenLogin)).pipe(first()).subscribe(data => {
            }, err => {
              this.router.navigate(['/login']);
            });
          else await (await this._authService.token(tokenLogin)).pipe(first()).subscribe(data => {
          }, err => {
            this.router.navigate(['/login']);
          });
        }
      }, error => {
        this.router.navigate(['/login']);
      });
  }

  pushNotification() {
    // console.log('Initializing HomePage');
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result: any) => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      async (token: Token) => {
        // console.log('token.value', token.value);
        await this.storage.setItem('firebaseToken', token.value);
        // alert('Push registration success, token: ' + token.value);
      }
    );

    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        await Modals.alert({
          title: notification.title,
          message: notification.body
        });
        // let message = {
        //   title: notification.title,
        //   body: notification.body
        // };
        // alert('Push received from HOME: ' + JSON.stringify(notification));
        // const modalRef = this.modalService.open(NotificationModalComponent, { size: 'lg', backdrop: 'static', centered: true });
        // modalRef.componentInstance.message = message;
      }
    );

    // Some issue with our setup and push will not work
    // PushNotifications.addListener('registrationError',
    //   (error: any) => {
    //     alert('Error on registration: ' + JSON.stringify(error));
    //     console.log('JSON.stringify(error)', JSON.stringify(error));
    //   }
    // );

    // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener('pushNotificationReceived',
    //   (notification: PushNotification) => {
    //     let message = {
    //       title: notification.title,
    //       body: notification.body
    //     };
    //     alert('Push received: ' + JSON.stringify(notification));
    //   }
    // );


    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        await Modals.alert({
          title: notification.notification.data.title,
          message: notification.notification.data.body
        });
      }
    );
  }

  cookies() {

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    // this.initializeSubscription = this.ccService.initialize$.subscribe(
    //   (event: NgcInitializeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    //   (event: NgcStatusChangeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    //   this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    //   (event: NgcNoCookieLawEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

}