import { Injectable } from '@angular/core';
// import { TeamSelectionComponent } from './../teams-area/team-selection/team-selection.component';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import Chart from 'chart.js';
import { AuthenticationService } from 'providers/authentication.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { first } from 'rxjs/operators';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { OnboardService } from 'app/onboard.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private _authService: AuthenticationService) { }

  user: string = '';
  token: string = '';
  subscriptionUser: Subscription;

  async getUser(){
    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      const user: any[] = value ? JSON.parse(value) : {};
      this.user = user['_id'];
      this.token = user['token'];
    });

  }

  async getNotifications(){
    const notificationVm = {
      id: this.user,
      token: this.token,
      // recipients: recip
    };
  
    await (await this._authService.getNotifications(notificationVm)).subscribe(res => {
      console.log(res)
    })
  }

 

}
