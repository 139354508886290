import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'providers/authentication.service';
import { ConfigService } from 'providers/config/config.service';
import {  Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-disapprove-projects',
  templateUrl: './disapprove-projects.component.html',
  styleUrls: ['./disapprove-projects.component.css']
})

export class DisapproveProjectsComponent implements OnInit {
  isUserAMember: boolean = false;

  user: string = '';
  token: string = '';
  id_challenge: string = '';
  ideaId = '';

  admin: boolean = true;
  isChallengeExpired: boolean = false;
  allIdeasRecords: any;
  idea: { id: any; title: any; text: any; adminApproved: any; skills: any[]; };

  private subscriptionUser: Subscription;

  success = false;
  loading = false;
  submitted = false;
  showError = false;

  Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(
    private _authService: AuthenticationService,
    private readonly _configService: ConfigService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  public async ngOnInit() {
    this.subscriptionUser = this._authService.authUserChange.subscribe(async value => {
      let user: any[] = value ? JSON.parse(value) : {}
      this.user = user['_id'];
      this.token = user['token'];

      if (user['company'] && (user['role'] == 'Admin' || user['role'] == 'CompanyAdmin')) this.admin = true;
      else this.admin = false;

      if (!this.admin) this.router.navigate(['/dashboard']);
    });
    this.ideaId = await this._authService.getIdea();
    if (!this.ideaId) this.router.navigate(['/previous-challenges']);

    this.getChallengeIdea();
  }

  async getChallengeIdea() {
    this.allIdeasRecords = [];
    const ideaVm = {
      id: this.user,
      token: this.token,
      idea: this.ideaId,
    };

    await (await this._authService.getSingleIdea(ideaVm)).subscribe(newData => {
      this.allIdeasRecords = [];

      let skills = [];
      newData.skills.forEach(element => {
        skills.push(element.display);
      });
      this.idea = { "id": newData._id, "title": newData.title, "text": newData.text, "adminApproved": newData.adminApproved, "skills": skills }
    }, err => {
      console.log('error');
    });
  }

  async disapproveIdea() {

    this.submitted = true;
    this.showError = false;

    const ideaVm = {
      id: this.user,
      token: this.token,
      idea: this.ideaId,
      adminApproved: false
    };

    this.loading = true;

    await (await this._authService.disapproveProjects(ideaVm))
      .pipe(first()).subscribe(async data => {
        this.Toast.fire({
          icon: 'success',
          title: 'The project has been disapproved'
        })
        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(5000);
        this.router.navigate(['/company-admin-approve-projects']);
      }, async err => {
        this.Toast.fire({
          icon: 'error',
          title: 'An error has occurred. Please try again.'
        })
      })
  }
}
